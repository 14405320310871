import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyTable2 = () => {
  const { t } = useTranslation('privacyPolicy');

  return (
    <div className="flex-table flex-table-3cols">
      <div className="flex-table-cell">
        <strong>{t("Purpose/Activity")}</strong>
      </div>
      <div className="flex-table-cell"><strong>{t("Type of Data")}</strong></div>
      <div className="flex-table-cell">
        <strong>{t(
          "Lawful basis for processing including basis of legitimate interest"
        )}</strong>
      </div>
      {/* ---------- */}
      <div className="flex-table-cell">
        {t("To register you as a new customer")}
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Identity")}</p>
        <p>(b) {t("Contact")} </p>
      </div>
      <div className="flex-table-cell">
        {t("Performance of a contract with you")}
      </div>
      {/* ---------- */}
      <div className="flex-table-cell">
        <p>{t("To process and deliver your order of services including:")}</p>
        <p>(a) {t("Manage payments, fees and charges")}</p>
        <p>(b) {t("Collect and recover money owed to us")}</p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Identity")}</p>
        <p>(b) {t("Contact")} </p>
        <p>(c) {t("Financial")} </p>
        <p>(d) {t("Transaction")} </p>
        <p>(e) {t("Marketing and Communications")} </p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Performance of a contract with you")}</p>
        <p>
          (b){" "}
          {t(
            "Necessary for our legitimate interests (to recover debts due to us)"
          )}
        </p>
      </div>
      {/* ------------- */}
      <div className="flex-table-cell">
        <p>{t("To manage our relationship with you which will include")}:</p>
        <p>
          (a) {t("Notifying you about changes to our terms or privacy policy")}
        </p>
        <p>(b) {t("Asking you for feedback")}</p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Identity")}</p>
        <p>(b) {t("Contact")}</p>
        <p>(c) {t("Profile")} </p>
        <p>(c) {t("Marketing and Communications")} </p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Performance of a contract with you")}.</p>
        <p>(b) {t("Necessary to comply with a legal obligation")}.</p>
        <p>
          (c){" "}
          {t(
            "Necessary for our legitimate interests (to keep our records updated and \
            to study how customers use our products/services)"
          )}
        </p>
      </div>
      {/* --------------- */}
      <div className="flex-table-cell">
        <p>
          {t(
            "To administer and protect our business and this website (including troubleshooting, data analysis, \
testing, system maintenance, support, reporting and hosting of data)"
          )}
        </p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Identity")}</p>
        <p>(b) {t("Contact")}</p>
        <p>(c) {t("Technical")} </p>
      </div>
      <div className="flex-table-cell">
        <p>
          (a){" "}
          {t(
            "Necessary for our legitimate interests (for running our business, provision of administration and IT services, \
network security, to prevent fraud and in the context of a business reorganization or group restructuring exercise)"
          )}
        </p>
        <p>(b) {t("Necessary to comply with a legal obligation")}.</p>
      </div>
      <div className="flex-table-cell">
        <p>
          {t(
            "To use data analytics to improve our website, products/services, marketing, customer relationships and experiences"
          )}
        </p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Technical")} </p>
        <p>(b) {t("Usage")} </p>
      </div>
      <div className="flex-table-cell">
        <p>
          (a){" "}
          {t(
            "Necessary for our legitimate interests (to define types of customers for our products and services, \
to keep our website updated and relevant, to develop our business and to inform our marketing strategy)"
          )}
        </p>
        <p>(b) {t("Necessary to comply with a legal obligation")}.</p>
      </div>
      <div className="flex-table-cell">
        <p>
          {t(
            "To make suggestions and recommendations to you about goods or services that may be of interest to you"
          )}
        </p>
      </div>
      <div className="flex-table-cell">
        <p>(a) {t("Identity")}</p>
        <p>(b) {t("Contact")}</p>
        <p>(c) {t("Technical")} </p>
        <p>(d) {t("Usage")} </p>
        <p>(e) {t("Profile")} </p>
      </div>
      <div className="flex-table-cell">
        <p>
          (a){" "}
          {
            "Necessary for our legitimate interests (to develop our products/services and grow our business)"
          }
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyTable2;
