import React from "react";
import { Modal } from "react-bootstrap";
import TermsOfUse from "../TermsOfUse";

const Terms = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbody">
        <TermsOfUse />
      </Modal.Body>
    </>
  );
};

export default Terms;
