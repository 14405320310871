import React, { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { VscGlobe } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

const LanguageSelect = () => {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n, t } = useTranslation();
  const location = useLocation();

  const LanguageField = () => {
    return (
      <>
        <VscGlobe />
        <span className="language-field">{cookies.lang}</span>
      </>
    );
  };

  // Initialize component
  useEffect(() => {
    if (cookies.lang == null) {
      setCookie("lang", i18n.language, { sameSite: "strict" });
    }
    i18n.changeLanguage(cookies.lang);
  }, [i18n, cookies.lang, setCookie]);

  const handleClick = (lang) => {
    setCookie("lang", lang, { sameSite: "strict" });
    i18n.changeLanguage(lang);
  };

  //checks if the user is on the specified page and re-renders
  const isCreatingBook = location.pathname == "/create_book";

  useEffect(() => {}, [isCreatingBook]);

  return (
    <div className="lang-dropdown-container">
      {location.pathname == "/create_book" ? (
        <div className="lang-dropdown-info">
          <span className="lang-dropdown-tooltip">
            {t(
              "navbar>You can not change to a different locale while writing a book",
            )}
          </span>
        </div>
      ) : null}
      <div
        className={
          location.pathname == "/create_book"
            ? "lang-dropdown-wrapper-disabled "
            : "lang-dropdown-wrapper"
        }
      >
        <DropdownButton
          className="dropdown-btn res-font-extra-bold-medium"
          alignRight
          label={<LanguageField />}
          title={<LanguageField />}
          id="dropdown-menu-align-right"
          //onSelect={handleSelect}
        >
          <Dropdown.Item
            onClick={() => {
              handleClick("en");
            }}
          >
            EN
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleClick("fi")}>FI</Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
};

export default LanguageSelect;
