import $ from "jquery";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from 'react-js-banner';
import CloseIcon from "@material-ui/icons/Close";
import api from "../../axios"

import { selectProfile } from "../../features/profileSlice";
import { auth } from "../../features/authSlice";
import styles from "../../styles/components/_emailbanner.module.scss";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

export default function EmailVerifyBanner() {

  const { t } = useTranslation();

  const { profile } = useSelector(selectProfile)
  const { isAuthenticated } = useSelector(auth);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false)
  }

  const sendVerifyEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    api.post("/profiles/email_verify_request/", {email: profile.user_info.email})
    .then(() => {
      setLoading(false)
      setSuccess(true)
    })
    .catch(() => {
      setLoading(false)
      setFailure(true)
    })
  }

  return (
    <>
    {
    <Banner
      showBanner={show&&isAuthenticated&&($.isEmptyObject(profile)?false:!(profile.user_info.email_verified))}
      css={{
        fontFamily: "Raleway-Bold",
        marginBottom: 0,
      }} 
    >
      {loading?
      <>
      <Spinner /> {t("email>Loading...")}
      </>
      :success?
      <>
        <span>{t("email>Email sent successfully. You may close this banner.")}</span>
      </>
      :failure?
      <>
        <span>{t("email>There was an error sending the email. Try again later.")}</span>
      </>
      :
      <>
        <span>{t("email>You have not verified your email yet.")}
        {" "}
        <a 
        style={{color: "inherit"}}
        href="#"
        onClick={(e) => sendVerifyEmail(e)}
        >
          {t("email>Click here")}
        </a> 
        {" "}
        {t("email>to send a verification email.")}</span>
      </>
      }
      <button
      className={"button button-transparent "+ styles.closeIcon}
      onClick={() => handleClose()}
      >
        <CloseIcon  />
      </button>
    </ Banner>
  }
  </>
  );
}
