import axios from "axios";
import store from "./store";
import api from "./axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {silentRenew, silentRenewFailure, auth} from "./features/authSlice";
import i18next from "i18next";

let Promise = require('promise');

// .env values
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET.replace('"','');
const openIDUrl = process.env.REACT_APP_API_ROOT + "/openid/";

// Variables for concurrency interceptors
const MAX_REQUESTS_COUNT = 5
const INTERVAL_MS = 100
let PENDING_REQUESTS = 0


function getAccessToken(){
  return sessionStorage.getItem('access_token');
}

export const setupInterceptors = (axiosInstance) =>
{
  
  // Request interceptor for API calls
  axiosInstance.interceptors.request.use(
      request => {
        request.headers = { 
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
        request.headers['Accept-Language'] = i18next.language;
        request.headers['Locale'] = i18next.language;
        if (getAccessToken()!=null)
          request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
        return request;
      },
      error => {
        Promise.reject(error)
  });

  const refreshAuthLogic = failedRequest => {
  if (!failedRequest.response.config.url.includes("openid/token"))
    return Promise.resolve();
  axios.post(openIDUrl+"token/", `grant_type=refresh_token`,
  {
    headers: { 
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
    },
    withCredentials: true
  })
  .then(tokenRefreshResponse => {
    sessionStorage.setItem('access_token', tokenRefreshResponse.data.access_token);
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
    store.dispatch(silentRenew(tokenRefreshResponse.data));
    return Promise.resolve();
  })
  .catch(() => {
    store.dispatch(silentRenewFailure());
  });
}

  createAuthRefreshInterceptor(
    api,
    refreshAuthLogic,
    {statusCodes: [ 401, 403 ]},
  );

  //========== Limit concurrent requests ==========


  const maxRequests = () => {
    if (auth(store.getState()).isLoading)
      return 1
    else
      return MAX_REQUESTS_COUNT
  }

  /**
  * Request Interceptor for concurrency
  */
   axiosInstance.interceptors.request.use(function (config) {
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (PENDING_REQUESTS < maxRequests()) {
          PENDING_REQUESTS++
          clearInterval(interval)
          resolve(config)
        }
      }, INTERVAL_MS)
    })
  })

  /**
   * Response Interceptor for concurrency
   */
   axiosInstance.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  }, function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  })
}
