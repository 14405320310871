import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { selectProfile } from "../../features/profileSlice";
import { auth, setNextURL } from "../../features/authSlice";
import { setLoginOrCreateAccount } from "../../features/modalSlice";

const SecondaryNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(auth);

  const { isStaff } = useSelector(selectProfile);

  const openModal = () => {
    dispatch(setNextURL("/share"));
    dispatch(setLoginOrCreateAccount(true));
  };

  return (
    <Navbar
      expanded={expanded}
      className="secondary-navbar justify-content-between"
      expand="lg"
    >
      <Navbar.Brand></Navbar.Brand>
      <Navbar.Toggle
        onClick={() => setExpanded(expanded ? false : "expanded")}
        aria-controls="basic-navbar-nav"
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto secondary-navbar-link-group">
          <Nav>
            {isAuthenticated ? (
              <Link
                onClick={() => setExpanded(false)}
                className="secondary-navbar-link"
                to="/share"
              >
                {t("navbar>Share Life Learnings")}
              </Link>
            ) : (
              <Link
                to="/share"
                className="secondary-navbar-link"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                {t("home>Share Life Learnings")}
              </Link>
            )}
          </Nav>
          <Nav>
            <Link
              onClick={() => setExpanded(false)}
              className="secondary-navbar-link"
              to="/search"
            >
              {t("navbar>Search Life Learnings")}
            </Link>
          </Nav>
          {isStaff ? (
            <Nav>
              <Link
                onClick={() => setExpanded(false)}
                className="secondary-navbar-link"
                to="/lile-admin"
              >
                {t("navbar>Admin Page")}
              </Link>
            </Nav>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default SecondaryNavbar;
