import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

import AboutUsContent from "./modalContents/AboutUsContent";
import TermsContent from "./modalContents/TermsContent";
import PrivacyPolicyContent from "./modalContents/PrivacyPolicyContent";
import ContactUsContent from "./modalContents/ContactUsContent";
import ContactUsFormSentContent from "./modalContents/ContactUsFormSentContent";
import { selectShowModal, setPrivacyPolicy, setTermsOfUse} from "../../features/modalSlice";

const FooterModals = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [showContactUsSent, setShowContactUsSent] = useState(false);

  const { showPrivacyPolicy, showTermsOfUse } = useSelector(selectShowModal);

  const [content, setContent] = useState(null);

  const openModalAboutUs = () => {
    setShowAboutUs(true);
    setContent(<AboutUsContent />);
  };

  const openModalTerms = () => {
    dispatch(setTermsOfUse(true));
  };
  const openModalPrivacyPolicy = () => {
    dispatch(setPrivacyPolicy(true));
  };

  const closeModalContactUs = () => {
    setShowContactUs(false);
    openModalContactUsFormSent();
  };

  const closeModalContactUsFormSent = () => {
    setShowContactUsSent(false);
    //If the contactus modal is closed via the send button the thank you modal will be displayed.
    //In this way contactusformsent modal will not be a child of contactus modal and allows
    // closing them separetly.
    setContent(<ContactUsFormSentContent />);
  };

  const openModalContactUsFormSent = () => {
    setShowContactUsSent(true);
    setContent(
      <ContactUsFormSentContent
        closeModalContactUsFormSent={closeModalContactUsFormSent}
      />
    );
  };

  const openModalContactUs = () => {
    setShowContactUs(true);
    setContent(
      <ContactUsContent
        closeModalContactUs={closeModalContactUs}
        openModalContactUsFormSent={openModalContactUsFormSent}
      />
    );
  };

  return (
    <>
      <ul>
        <li onClick={() => openModalAboutUs()}>{t("footer>About Us")}</li>
        <li onClick={() => openModalTerms()}>{t("footer>Terms of Use")}</li>
        <li onClick={() => openModalPrivacyPolicy()}>{t("footer>Privacy Policy")}</li>
        <li onClick={() => openModalContactUs()}>{t("footer>Contact Us")}</li>
      </ul>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {content}
      </Modal>
      <Modal
        show={showAboutUs}
        onHide={() => setShowAboutUs(false)}
        scrollable={true}
        animation={false}
        dialogClassName="modal-xl"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {content}
      </Modal>

      <Modal
        size="xl"
        show={showPrivacyPolicy || showTermsOfUse}
        onHide={() => {
          dispatch(setPrivacyPolicy(false));
          dispatch(setTermsOfUse(false));
        }}
        animation={false}
        scrollable={true}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {showPrivacyPolicy?
        <PrivacyPolicyContent />
        :
        showTermsOfUse?
        <TermsContent />:
        ""}
      </Modal>

      <Modal
        show={showContactUs}
        onHide={() => setShowContactUs(false)}
        animation={false}
        dialogClassName="modal-xl"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {content}
      </Modal>

      <Modal
        show={showContactUsSent}
        onHide={() => setShowContactUsSent(false)}
        animation={false}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {content}
      </Modal>
    </>
  );
};

export default FooterModals;
