import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Dropdown } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SignInContent from "./modalContents/SignInContent";
import LogInContent from "./modalContents/LogInContent";
import LoginOrCreateAccountModal from "../../components/modals/LoginOrCreateAccount";
import { logOutUser, auth } from "../../features/authSlice";
import { selectProfile, fetchUserProfile } from "../../features/profileSlice";
import {
  selectShowModal,
  setSignIn,
  setLogin,
} from "../../features/modalSlice";

const NavbarModals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(auth);
  const { profile } = useSelector(selectProfile);
  const { showSignIn, showLogin } = useSelector(selectShowModal);

  let username = "";
  let userId = null;

  if (Object.keys(profile).length > 0 && profile.constructor === Object) {
    username = profile.user_info.username;
    userId = profile.id;
  } else if (Object.keys(profile).length == 0) {
    userId = null;
    username = "";
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, isAuthenticated]);

  const openModalSign = () => {
    dispatch(setSignIn(true));
  };

  const openModalLogin = () => {
    dispatch(setLogin(true));
  };

  const logOut = () => {
    dispatch(logOutUser());
  };

  const questButtons = (
    <div className="navbar_btn_group">
      <Button className="btn_join_for_free" onClick={() => openModalSign()}>
        {t("navbar>JOIN FOR FREE")}
      </Button>
      <Button
        className="button-black btn_login"
        onClick={() => openModalLogin()}
      >
        {t("navbar>Login")}
      </Button>
      <Modal
        show={showLogin}
        onHide={() => dispatch(setLogin(false))}
        animation={false}
        dialogClassName="modalLogin"
      >
        <LogInContent />
      </Modal>
      <Modal
        size="xl"
        show={showSignIn}
        onHide={() => dispatch(setSignIn(false))}
        animation={false}
        dialogClassName="modalSignin"
      >
        <SignInContent />
      </Modal>
      <LoginOrCreateAccountModal />
    </div>
  );

  const AuthButton = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const ref = useRef();
    const toggleShowDropdown = () => {
      setShowDropdown(!showDropdown);
    };

    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (showDropdown) {
          toggleShowDropdown();
        }
      }
    };
    useEffect(() => {
      document.addEventListener("click", handleOutsideClick);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    });

    return (
      <>
        <div className="navbar_btn_group navbar-dropdown-wrapper">
          <Dropdown
            show={showDropdown}
            onClick={() => toggleShowDropdown()}
            ref={ref}
          >
            <Dropdown.Toggle id="dropdown-basic" className="center-dropdown">
              <span className="profile-menu-text truncate" key={userId}>
                {username}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.ItemText onClick={() => navigate("/profile")}>
                <span className="dropdown-itemtext">{t("navbar>Profile")}</span>
              </Dropdown.ItemText>
              <Dropdown.ItemText
                onClick={() => navigate("/my_learnings")}
              >
                <span className="dropdown-itemtext">
                  {t("navbar>My Life Learnings")}
                </span>
              </Dropdown.ItemText>
              <Dropdown.ItemText
                onClick={() => navigate("/saved_learnings")}
              >
                <span className="dropdown-itemtext">
                  {t("navbar>Saved Life Learnings")}
                </span>
              </Dropdown.ItemText>
              <Dropdown.ItemText onClick={() => navigate("/myaccount")}>
                <span className="dropdown-itemtext">
                  {t("navbar>My Account")}
                </span>
              </Dropdown.ItemText>
              <Link to="/">
                <Dropdown.Item
                  as="button"
                  className="button-transparent button-logout"
                  onClick={() => logOut()}
                >
                  {t("navbar>Logout")}
                </Dropdown.Item>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };
  if (isAuthenticated) {
    return <AuthButton />;
  } else {
    return questButtons;
  }
};

export default NavbarModals;
