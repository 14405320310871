import React from "react";
import PropTypes from "prop-types";

const OpenPdfLink = (props) => {
  OpenPdfLink.propTypes = {
    spanText1: PropTypes.string,
    document: PropTypes.string,
    text: PropTypes.string,
    spanText2: PropTypes.string,
  };

  return (
    <>
      <span>{props.spanText1}</span>
      &nbsp;
      <a
        className="link-black-hover-black res-font-regular-medium-4"
        href={props.document}
        target="_blank"
        rel="noreferrer"
      >
        {props.text}
      </a>
      &nbsp;
      <span>{props.spanText2}</span>
    </>
  );
};

export default OpenPdfLink;
