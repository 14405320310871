import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "../../styles/components/modals/_signin.module.scss";
import CheckBox from "../utils/CheckBox";

import {
  selectCategories,
  fetchCategories,
} from "../../features/categoriesSlice";
import { registerNewUser, selectProfile } from "../../features/profileSlice";
import { setPrivacyPolicy, setTermsOfUse } from "../../features/modalSlice";
import SigninSuccessModal from "./SigninSuccessModal";
import CharacterCountField from "../utils/CharacterCountField";

import picture1png from "../../assets/images/sign-in-picture1.2.png";
import picture2png from "../../assets/images/sign-in-picture2.2.png";

import picture1 from "../../assets/images/lil-homepage-img-1.png";
import picture2 from "../../assets/images/lil-homepage-img-2.png";

import PropTypes from "prop-types";
import IsHuman from "../utils/IsHuman";
import AppConstants from "../../constants/AppConstants";

const SigninForm = () => {
  SigninForm.propTypes = {
    closeModal: PropTypes.func,
  };

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { categories } = useSelector(selectCategories);
  const { hasErrors, errorMsg } = useSelector(selectProfile);

  const [show, setShow] = useState(false);
  const [capsLock, setCapsLock] = useState(false);
  const [token, setToken] = useState(null);
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  const getCategorySlugArray = (categories) => {
    let res = [];
    categories.forEach((cat) => {
      res.push(cat.slug);
    });
    return res;
  };

  const getLoginObject = (fields) => {
    let res = {
      grant_type: "password",
      username: fields.email,
      password: fields.password1,
      scope: "openid email profile",
    };
    return res;
  };
  return (
    <>
      <div className="modal-width">
        <div className={styles.signinContainer}>
          <div className={styles.Logo}>
            <div className="logotext">
              <span className="life">Life</span>
              <span className="learning">Learnings</span>
            </div>
          </div>
          <Formik
            enableReinitialize // Re-render when initialValues changes
            initialValues={{
              username: "",
              password1: "",
              password2: "",
              email: "",
              instagram: "",
              acceptTerms: false,
              preferred_categories: getCategorySlugArray(categories),
              receive_roundup_emails: true,
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .min(5,
                  t("validation>Minimum length is {{length}} characters", {
                    length: 5,
                  })
                )
                .max(
                  30,
                  t("validation>Maximum length is {{length}} characters", {
                    length: 30,
                  })
                )
                .required(t("signIn>Username is required"))
                .matches(
                  AppConstants.RE_USERNAME,
                  t(
                    "validation>Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
                  )
                ),
                instagram: Yup.string()
                .min(3,
                  t("validation>Minimum length is {{length}} characters", {
                    length: 3,
                  })
                )
                .max(
                  30,
                  t("validation>Maximum length is {{length}} characters", {
                    length: 30,
                  })
                )
                .matches(
                  AppConstants.IG_USERNAME,
                  t(
                    "validation>Enter a valid instagram username. This value may contain only letters, numbers, and ./_ characters."
                  )
                ),
              password1: Yup.string()
                .min(8, t("signIn>Your password must be at least 8 characters"))
                .matches(
                  /^(?=.*[A-Za-z])/,
                  t("signIn>Your password can't be entirely numeric")
                )
                .required(t("signIn>Password is required")),
              password2: Yup.string()
                .oneOf(
                  [Yup.ref("password1"), null],
                  t("signIn>Passwords don't match")
                )
                .required(t("signIn>Password is required")),
              email: Yup.string()
                .email(t("signIn>Must be a valid email address"))
                .required(t("signIn>Email is required")),
              acceptTerms: Yup.bool().oneOf(
                [true],
                t("signIn>Please accept terms of use to proceed")
              ),
              preferred_categories: Yup.array().required(
                t("validation>Select at least one category.")
              ),

              receive_roundup_emails: Yup.bool(),
            })}
            onSubmit={(fields, { setErrors }) => {
              if (token !== null) {
                fields.token = token;
                dispatch(registerNewUser(fields, setShow, setErrors));
              } else {
                let msg = document.getElementById("reCaptcha-error");
                msg.className += styles.reCaptchaError;
                msg.innerHTML = i18n.t(
                  "signIn>Please verify that you're not a robot."
                );
              }
            }}
          >
            {({ values }) => (
              <Form className={styles.baseForm}>
                <div className={styles.columnWrap}>
                  <div className={styles.firstColumn}>
                    <div className="res-font-regular-small">
                      <span>
                        {t(
                          "signIn>Save your life learnings/mini books, track your favorite \
topics and gain access to a bunch of fun member-only \
features by creating a free profile!"
                        )}
                      </span>
                    </div>
                    <div className={styles.signinForm + " mt-4"}>
                      <div className={styles.formRow + " mt-3"}>
                        <label
                          className={styles.signinLabel + " res-font-formlabel"}
                          htmlFor="username"
                        >
                          {t("signIn>*User Name")}
                        </label>

                        <div
                          className={
                            styles.usernameNote + " res-font-regular-small"
                          }
                        >
                          {t(
                            "signIn>NOTE: Your writer name will be visible to other members \
if you create a mini book. Your writer name can be edited in your profile."
                          )}
                        </div>
                        <Field
                          id="username"
                          name="username"
                          type="text"
                          as={CharacterCountField}
                          className={
                            "form-control"
                          }
                          charsLeftClassName={styles.usernameCharsLeft}
                          maxLength={30}
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className={styles.formRow}>
                        <label
                          className={styles.signinLabel + " res-font-formlabel"}
                          htmlFor="email"
                        >
                          {t("signIn>*Email address")}
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className={
                            "form-control"
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className={styles.checkboxField}>
                        <label
                          className="res-checkbox-label"
                          htmlFor="receive_roundup_emails"
                        >
                          <CheckBox
                            id="receive_roundup_emails"
                            name="receive_roundup_emails"
                            type="checkbox"
                            className={
                              "res-checkbox"
                            }
                          />
                          {t("signIn>Email Notifications ON")}
                        </label>
                        <ErrorMessage
                          name="receive_roundup_emails"
                          component="div"
                          className="error-message error-message-fix"
                        />
                      </div>
                      <div
                        className={
                          styles.checkboxNote + " res-font-regular-medium-small"
                        }
                      >
                        {t(
                          "signIn>I agree to receive emails from Life Learnings (e.g. mini \
book suggestions, learning trends)"
                        )}
                      </div>
                      <div className={styles.formRow + " mt-3"}>
                        <label
                          className={styles.signinLabel + " res-font-formlabel"}
                          htmlFor="password1"
                        >
                          {t("signIn>*Create Password")}
                        </label>
                        <Field
                          id="password1"
                          name="password1"
                          type="password"
                          autoComplete="on"
                          onKeyDown={onKeyDown}
                          className={
                            "form-control"
                          }
                        />
                        <ErrorMessage
                          name="password1"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className={styles.formRow + " mt-3"}>
                        <label
                          className={styles.signinLabel + " res-font-formlabel"}
                          htmlFor="password2"
                        >
                          {t("signIn>*Retype Password")}
                        </label>
                        <Field
                          id="password2"
                          name="password2"
                          type="password"
                          autoComplete="on"
                          onKeyDown={onKeyDown}
                          className={
                            "form-control"
                          }
                        />
                        {capsLock ? (
                          <div className="caps-lock-message">
                            {t("modal>Caps Lock is enabled")}
                          </div>
                        ) : (
                          ""
                        )}
                        <ErrorMessage
                          name="password2"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className={styles.checkboxField + " mt-3"}>
                        <label
                          className="res-checkbox-label"
                          htmlFor="acceptTerms"
                        >
                          <CheckBox
                            id="acceptTerms"
                            name="acceptTerms"
                            type="checkbox"
                            className={
                              "res-checkbox "
                            }
                          />
                          {t("signIn>*Accept Terms")}
                        </label>
                        <ErrorMessage
                          name="acceptTerms"
                          component="div"
                          className={styles.invalidCheckBoxField}
                        />
                      </div>
                      <div
                        className={
                          styles.checkboxNote + " res-font-regular-medium-small"
                        }
                      >
                        {t("signIn>I have read and agree to the")}&nbsp;
                        <button
                          className="button button-transparent res-font-regular-medium-small"
                          type="button"
                          onClick={() => dispatch(setTermsOfUse(true))}
                          style={{ padding: "0px" }}
                        >
                          {t("signIn>Terms of Use")}
                        </button>
                        &nbsp;{t("signIn>and")}&nbsp;
                        <button
                          className="button button-transparent res-font-regular-medium-small"
                          type="button"
                          onClick={() => dispatch(setPrivacyPolicy(true))}
                          style={{ padding: "0px" }}
                        >
                          {t("signIn>Privacy Policy.")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={styles.secondColumn}>
                    <div>
                      <div className={styles.topicsHeader + " res-font-header"}>
                        <label>
                          {t("signIn>Choose Life Categories")}
                          <br />
                          {t("signIn>(Optional)")}
                        </label>
                      </div>
                      <div
                        className={
                          styles.categoriesText + " res-font-regular-small"
                        }
                      >
                        <span>
                          {t(
                            "signIn>Select all your favorite life topics below. This \
allows us to show you the kind of content that matches \
your specific interests."
                          )}
                        </span>
                      </div>
                      <ul className={styles.topicsList}>
                        {categories.map((category) => (
                          <li key={category.id} className="formrow">
                            <label
                              className={
                                styles.lifetopicLabel + " res-checkbox-label"
                              }
                            >
                              <CheckBox
                                name="preferred_categories"
                                type="checkbox"
                                value={category.slug}
                                className={
                                  "lifetopic res-checkbox"
                                }
                              />

                              {i18n.language == "fi"
                                ? category.name_fi
                                : category.name_en}
                            </label>
                          </li>
                        ))}
                      </ul>
                      <ErrorMessage
                        name="preferred_categories"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div>
                      <label
                        className={styles.signinLabel + " res-font-formlabel"}
                        htmlFor="instagram"
                      >
                        {t("signIn>We can promote you")}
                      </label>
                      <div
                        className={
                          styles.instagtamNote +
                          " res-font-regular-medium-small"
                        }
                      >
                        <p>
                          {t(
                            "signIn>Instagram promotion note"
                          )}
                        </p>
                      </div>

                      <Field
                        id="instagram"
                        name="instagram"
                        type="text"
                        onKeyDown={onKeyDown}
                        className={"form-control"}
                      />
                      <ErrorMessage
                          name="instagram"
                          component="div"
                          className="error-message"
                        />
                    </div>
                    <div>
                      <IsHuman
                        setToken={setToken}
                        className={styles.isHumanContainer}
                        errorMsgElem={document.getElementById(
                          "reCaptcha-error"
                        )}
                      />
                    </div>
                    <div id="reCaptcha-error"></div>
                    <br />
                    <div className="status-message failure">
                      {hasErrors
                        ? errorMsg == ""
                          ? t("validation>An error occurred")
                          : errorMsg
                        : ""}
                    </div>
                  </div>
                  <div className={styles.thirdColumn}>
                    <div className={styles.imagesContainer}>
                      <ul className={styles.imagesGroup}>
                        <li className={styles.imagesItem}>
                          <picture>
                            <source srcSet={picture1png} type="image/png" />
                            <source srcSet={picture1} type="image/png" />
                            <img src={picture1} type="image/png" alt="phone" />
                          </picture>
                        </li>
                        <li className={styles.imagesItem}>
                          <picture>
                            <source srcSet={picture2png} type="image/png" />
                            <source srcSet={picture2} type="image/png" />
                            <img
                              src={picture2}
                              type="image/png"
                              alt="Thinking"
                            />
                          </picture>
                        </li>
                      </ul>
                    </div>

                    <div className={styles.signinButtonContainer}>
                      <button
                        className={styles.signinButton + " button-black"}
                        type="submit"
                      >
                        {t("signIn>Submit")}
                      </button>
                    </div>
                  </div>
                </div>
                <SigninSuccessModal
                  show={show}
                  setShow={setShow}
                  loginFields={getLoginObject(values)}
                />
              </Form>
            )}
          </Formik>

          {/* <button onClick={() => setShow(true)}>SHOW MODAL</button> */}
        </div>
      </div>
    </>
  );
};

export default SigninForm;
