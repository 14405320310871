import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { auth } from "../../features/authSlice";
import { useSelector } from "react-redux";
import { GetFullStoryLI } from "../list_component/GetFullStoryLI";
import { StarRatingLI } from "../list_component/StarRatingLI";
import { useWindowSize } from "../../CustomHooks/UseWindowSize";
import constants from "../../constants/AppConstants";

export const MiniBookComponent = (props) => {
  MiniBookComponent.propTypes = {
    isAdmin: PropTypes.bool,
    minibooks: PropTypes.array,
    numberOfBooks: PropTypes.number,
    reloadFunc: PropTypes.func,
  };
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector(auth);
  const renderTablet = isTablet && !isMobile;
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize <= constants.SCREEN_LG_MAX) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
    if (windowSize <= constants.SCREEN_SM_MAX) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize, isMobile, isTablet]);
  return (
    <>
      {props.minibooks.length == 0 && isAuthenticated ? (
        <ul className="flexbox minibook-feed-flexbox no-results-minibook-feed-flexbox">
          <li className="list-item first">
            <div className="list-item-text-wrapper">
              <div className="no-results res-font-bold-small">
                {t(
                  "list>No Mini Books can be found with the categories you have selected."
                )}
              </div>
            </div>
          </li>
        </ul>
      ) : (
        props.minibooks.slice(0, props.numberOfBooks).map((minibook) => (
          <ul
            className={`flexbox minibook-feed-flexbox ${
              minibook.related_learning &&
              minibook.related_learning_content.length <
                constants.LONG_LEARNING_LENGTH &&
              minibook.title.length < constants.LONG_TITLE_LENGTH
                ? "minibook-feed-normal-row"
                : "minibook-feed-high-row"
            }`}
            key={minibook.id}
          >
            <li className="list-item first">
              <div className="list-item-text-wrapper">
                <p className="res-font-bold-small">{minibook.title}</p>
                <p className="res-font-regular-small small-text-item">
                  {t("list>Category")}: {minibook.category_name}
                </p>
                <p className="res-font-regular-small small-text-item">
                  {t("list>Writer")}: {minibook.username}
                </p>
              </div>
            </li>
            {renderTablet && (
              <StarRatingLI minibook={minibook} reloadFunc={props.reloadFunc} />
            )}
            <li className="list-item second">
              <div className="list-item-text-wrapper">
                <div className="res-font-regular-small minibook-feed-related-learning-container ">
                  <p>{minibook.related_learning_content}</p>
                </div>
              </div>
            </li>
            {!renderTablet && (
              <StarRatingLI minibook={minibook} reloadFunc={props.reloadFunc} />
            )}

            <GetFullStoryLI minibook={minibook} isAdmin={props.isAdmin} />
          </ul>
        ))
      )}
    </>
  );
};

export default MiniBookComponent;
