import React from "react";
import PropTypes from "prop-types";

import StarRating from "../../components/utils/StarRating";

export const StarRatingLI = (props) => {
  StarRatingLI.propTypes = {
    minibook: PropTypes.object,
    reloadFunc: PropTypes.func,
  };
  return (
    <li className="list-item third">
      <div className="rating-wrapper">
        <StarRating
          idOrSlug={props.minibook.slug}
          avgRating={props.minibook.avg_rating}
          ratingsCount={props.minibook.ratings_count}
          isBook={true}
          reloadFunc={props.reloadFunc}
        />
        <div className="ratings-amount">
          <span className="ratings-amount-text">
            ({props.minibook.ratings_count})
          </span>
        </div>
      </div>
    </li>
  );
};

export default StarRatingLI;
