import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { loginUser } from "../../features/authSlice";
import styles from "../../styles/components/modals/_signin.module.scss";

import { hideAll } from "../../features/modalSlice";

import PropTypes from "prop-types";

const SigninSuccessModal = (props) => {
  SigninSuccessModal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    loginFields: PropTypes.object,
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (props) => {
    dispatch(loginUser(props.loginFields));
    dispatch(hideAll());
  };

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => handleClose(props)}
      animation={false}
      className="nested-modal-dim"
    >
      <div className={styles.successModal}>
        <div className="logotext">
          <span className="life">Life</span>
          <span className="learning">Learnings</span>
        </div>
        <div className={styles.successTitle + " res-font-extra-bold-big"}>
          {t("signIn>Thank you for joining Life Learnings!")}
        </div>
        <div className={styles.successText + " res-font-bold-medium-big"}>
          {t(
            "signIn>Ready to make an impact? We can’t wait to hear about your life experiences and \
hope you learn as much as we have from our wonderful worldwide community."
          )}
        </div>

        <button
          onClick={() => {
            handleClose(props);
          }}
          className={styles.successButton + " button-black"}
        >
          {t("signIn>Start Exploring")}
        </button>
      </div>
    </Modal>
  );
};

export default SigninSuccessModal;
