import React from "react";
import { Modal } from "react-bootstrap";
import PrivacyPolicy from "../PrivacyPolicy";

const PrivacyPolicyContent = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbody">
          <PrivacyPolicy />
      </Modal.Body>
    </>
  );
};

export default PrivacyPolicyContent;
