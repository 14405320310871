/* Select Input to be used inside Formik. Supports custom styling as opposed to
default HTML select with common browser overrides.

Example usage:
  <Field component={SelectField} name="category" options={getCategoryOptions(categories)}/>
Where:
  const getCategoryOptions = (categories) => {
    let categoryOptions = categories.map((category) => (
      {
        value: category.id,
        label: i18n.language=="fi"?category.name_fi:category.name,
      }))
      return categoryOptions;
    }
*/
import React from "react";
import Select from "react-select";
import { useField } from "formik";
import PropTypes from "prop-types";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    fontFamily: "Raleway-Bold",
    fontSize: "1.125rem",
    lineHeight: "1.275rem",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
      lineHeight: "1.125rem",
    },
  }),
  control: (provided) => ({
    ...provided,
    display: "flex",
    marginBottom: "0.5rem",
    paddingLeft: "1rem",
    minHeight: "50px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    borderRadius: "10px",
    "@media (max-width: 768px)": {
      minHeight: "44px",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    padding: "5px 8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
    textOverflow: "unset",
    whiteSpace: "unset",
    overflow: "unset",
  }),
  indicatorSeparator: () => ({
    color: "black",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
  }),
  option: (provided, state) => ({
    ...provided,
    ":active": {
      backgroundColor: "#0f0f0f",
    },
    color: "white",
    backgroundColor: state.isSelected ? "#151515" : "black",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "black",
    color: "white",
  }),
};

function SelectField(props) {
  SelectField.propTypes = {
    field: PropTypes.object,
    options: PropTypes.array,
    styles: PropTypes.object,
  };

  const [field, , { setValue, setTouched }] = useField(props.field.name);

  const onChange = ({ value }) => {
    setValue(value);
  };

  const matchValue = () => {
    let res = props.options.find((element) => element.value == field.value);
    if(res==undefined)
      return '';
    else
      return res;
  }

  return (
    <Select
      {...props}
      menuPosition="absolute"
      value={matchValue()}
      renderValue={(value) => value}
      onChange={onChange}
      onBlur={setTouched}
      styles={props.styles ? props.styles : customStyles}
    />
  );
}

export default SelectField;
