import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import NeedHelpModal from "../modals/NeedHelpModal";

const ListLastRow = (props) => {
  ListLastRow.propTypes = {
    handleClick: PropTypes.func,
    numberOfItems: PropTypes.number,
    maxNumberOfItems: PropTypes.number,
  };

  const [lgShow, setLgShow] = useState(false);
  const [content, setContent] = useState(null);

  const { t } = useTranslation();

  const openModalNeedHelp = () => {
    setLgShow(true);
    setContent(<NeedHelpModal />);
  };

  return (
    <>
      <ul className="list-last-row">
        <li className="need-help">
          <button
            className="button button-transparent"
            onClick={() => openModalNeedHelp()}
          >
            {t("list>Need help?")}
          </button>
        </li>
        <li className="show-more">
          <button
            className="btn-show-more button-transparent button"
            onClick={() => props.handleClick()}
          >
            {props.numberOfItems >= props.maxNumberOfItems ? (
              <span>{t("list>Show Less")}</span>
            ) : (
              <span>{t("list>Show More")}</span>
            )}
          </button>
        </li>
      </ul>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        animation={false}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {content}
      </Modal>
    </>
  );
};

export default ListLastRow;
