import React from "react";
import PropTypes from "prop-types";
import styles from "../../styles/components/_adbanner.module.scss";

const Advertising = (target, affiliateLinks) => {
  return affiliateLinks.reduce((acc, curr) => {
    if (curr.target_page === target) {
      acc.push(
        <div key={curr.id}>
          <AdBanner
            isVisible={true}
            link={curr.link}
            imageLink={curr.banner_image}
          />
        </div>
      );
    }
    return acc;
  }, []);
};

const AdBanner = (props) => {
  AdBanner.propTypes = {
    isVisible: PropTypes.bool,
    link: PropTypes.string,
    imageLink: PropTypes.string,
  };

  return (
    <>
      {props.isVisible ? (
        <div className={styles.bannerContainer}>
          <a target="_blank" rel="noreferrer" href={props.link}>
            <img src={props.imageLink} alt="Affiliate banner" />
          </a>
        </div>
      ) : null}
    </>
  );
};

export default Advertising;
