import React from "react";
import "./styles/main.scss";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";

import PrimaryNavbar from "./components/navbar/Navbar";
import SecondaryNavbar from "./components/navbar/SecondaryNavbar";
import EmailVerifyBanner from "./components/navbar/EmailVerifyBanner";
import ScrollToTop from "./components/utils/ScrollToTop"
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <PrimaryNavbar className="navbar" />
        <SecondaryNavbar className="secondary-navbar" />
        <EmailVerifyBanner />
        <Routes />
        <Footer />
      </Router>
    </>
  );
};

export default App;
