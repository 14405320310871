import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoginOrCreateAccount } from "../../features/modalSlice";
import { auth, setNextURL } from "../../features/authSlice";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/components/homepage/donationbanner.module.scss";
import book from "../../assets/images/book.png";

const BannerHomepage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(auth);

  const navigate = useNavigate();

  const openModal = (
    <button
      className={styles.bannerButton}
      onClick={() => {
        dispatch(setNextURL("/share"));
        dispatch(setLoginOrCreateAccount(true));
      }}
    >
      <p className={styles.bannerTitle}>{t("banner>Receive donations")}</p>
      <p className={styles.bannerText}>
        {t("banner>by Sharing Life Learnings")} <br />& &nbsp;
        {t("banner>Creating Mini Books")}
      </p>
    </button>
  );

  const redirectToSharePage = (
    <button
      className={styles.bannerButton}
      onClick={() => navigate("/share")}
    >
      <p className={styles.bannerTitle}>{t("banner>Receive donations")}</p>
      <p className={styles.bannerText}>
        {t("banner>by Sharing Life Learnings")} <br /> &
        {t("banner>Creating Mini Books")}
      </p>
    </button>
  );

  return (
    <div className={styles.donationBannerWrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.container}>
          <img className={styles.bookIcon} src={book} alt="Book" />
        </div>
        {isAuthenticated ? redirectToSharePage : openModal}
      </div>
    </div>
  );
};

export default BannerHomepage;
