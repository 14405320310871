import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "../../styles/components/_charactercount.module.scss";
import { TextareaAutosize } from "@material-ui/core";
import { useField } from "formik";

/**
 * Character count component for use with Formik Field components.
 */
const CharacterCountField = (props) => {
  CharacterCountField.propTypes = {
    display: PropTypes.oneOf(["input", "textarea", "TextareaAutosize"]),
    maxLength: PropTypes.number,
    excess: PropTypes.bool,
    className: PropTypes.string,
    resetCount: PropTypes.bool,
    setResetCount: PropTypes.func,
    //if needed, custom styles for to characters left output
    charsLeftClassName: PropTypes.string,
  };
  CharacterCountField.defaultProps = {
    display: "input",
    maxLength: 200,
    excess: false,
  };
  const [field] = useField(props);

  const { t } = useTranslation();
  const [count, setCount] = useState(field.value.length);

  const handleInput = (event) => {
    const target = event.currentTarget;
    setCount(target.value.length);
  };
  const componentProps = {
    className: props.className,
    maxLength: props.excess ? "" : props.maxLength,
    onInput: (e) => handleInput(e),
  };
  //we destruct the props to avoid having props as dependency and the useEffect beeing called everytime form is changed
  const resetCount = props.resetCount;
  const setResetCount = props.setResetCount;
  useEffect(() => {
    if (resetCount) {
      setResetCount(false);
      setCount(0);
    }
  }, [resetCount, setResetCount]);

  const displayElement = () => {
    switch (props.display) {
      case "input": {
        return <input {...field} {...componentProps} />;
      }
      case "textarea": {
        return <textarea {...field} {...componentProps} />;
      }
      case "TextareaAutosize": {
        return <TextareaAutosize {...field} {...componentProps} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <div>{displayElement()}</div>
      <div
        className={styles.container + " res-font-regular-small"}
        style={props.excess && count >= props.maxLength ? { color: "red" } : {}}
      ></div>
      <div
        className={
          styles.container +
          " res-font-regular-small " +
          props.charsLeftClassName
        }
      >
        {props.maxLength - count} {t("characters left")}
      </div>
    </>
  );
};

export default CharacterCountField;
