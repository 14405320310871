import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import picture1 from "../../../assets/images/contactus.jpg";
import picture1webp from "../../../assets/images/contactus.webp";
import CharacterCountField from "../../utils/CharacterCountField";
import SelectField from "../../utils/SelectField";
import api from "../../../axios";
import IsHuman from "../../utils/IsHuman";
import AppConstants from "../../../constants/AppConstants";


const ContactUs = (props) => {
  ContactUs.propTypes = {
    closeModalContactUs: PropTypes.func,
  };

  const [token, setToken] = useState(null);
  const { t } = useTranslation();

  const purposes = [
    t("contactUs>Payment Issues"),
    t("contactUs>Mini Books"),
    t("contactUs>Donations"),
    t("contactUs>Technical Issue"),
    t("contactUs>Feedback"),
    t("contactUs>Text Based Life Learnings"),
    t("contactUs>Profile"),
    t("contactUs>Other"),
  ];
  
  const sendForm = (values, token) => {
    values.token = token;
    values.purpose = purposes[values.purpose-1]
    api
      .post("/reports/send_contact_email/", values)
      .catch((err) => console.log(err.message));
  };

  const getPurposeOptions = (purposes) => {
    let purposeOptions = purposes.map((purpose, index) => ({
      value: index + 1,
      label: purpose,
    }));
    purposeOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    return purposeOptions;
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contactus" />
      </Modal.Header>
      <Modal.Body className="modalbody">
        <div className="contactus-container">
          <div className="login-logo">
            <div className="logotext">
              <span className="life">Life</span>
              <span className="learning">Learnings</span>
            </div>
          </div>
          <div className="contactustext-container">
            <div className="res-font-extra-header">
              {t("contactUs>Contact Us")}
            </div>
          </div>
          <div className="contactus-picture">
            <picture>
              <source srcSet={picture1webp} type="image/webp" />
              <source srcSet={picture1} type="image/jpeg" />
              <img src={picture1} type="image/jpeg" alt="keyboard" />
            </picture>
          </div>
          <div className="contactusform-wrapper">
            <Formik
              initialValues={{
                your_name: "",
                user_email: "",
                purpose: "",
                message: "",
              }}
              validationSchema={Yup.object().shape({
                your_name: Yup.string()
                  .min(2, t("contactUs>Too Short!"))
                  .max(50, t("contactUs>Too Long!"))
                  .required(t("contactUs>Your name is required!"))
                  .matches(AppConstants.RE_ALPHABETIC, t("validation>No special characters allowed.")),
                user_email: Yup.string()
                  .email(t("contactUs>Must be a valid email address"))
                  .required(t("contactUs>Please, type email address.")),
                purpose: Yup.string()
                  .ensure()
                  .required(t("contactUs>Purpose is required!")),
                message: Yup.string()
                  .max(500, t("validation>Maximum length is {{length}} characters", {length: 500}))
                  .min(3, t("contactUs>Minimum length is 3 character."))
                  .required(t("contactUs>Message is required!"))
                  .matches(AppConstants.RE_ALPHABETIC, t("validation>No special characters allowed.")),
              })}
              onSubmit={(values) => {
                if (token !== null) {
                  sendForm(values, token);
                  props.closeModalContactUs();
                } else {
                  let msg = document.getElementById("reCaptcha-error");
                  msg.className = "reCaptcha-error";
                  msg.innerHTML = i18n.t(
                    "contactUs>Please verify that you're not a robot."
                  );
                }
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="column left-column">
                      <div className="field-container">
                        <label
                          className="res-font-formlabel"
                          htmlFor="your_name"
                        >
                          {t("contactUs>*Your Name")}
                        </label>
                        <Field
                          className={
                            "formfield" +
                            (errors.your_name && touched.your_name
                              ? " is-invalid"
                              : "")
                          }
                          id="your_name"
                          name="your_name"
                          type="text"
                        />
                        <ErrorMessage
                          name="your_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="field-container">
                        <label
                          className="res-font-formlabel"
                          htmlFor="user_email"
                        >
                          {t("contactUs>*Email address")}
                        </label>
                        <Field
                          className={
                            "formfield" +
                            (errors.user_email && touched.user_email
                              ? " is-invalid"
                              : "")
                          }
                          id="user_email"
                          name="user_email"
                          type="email"
                        />
                        <ErrorMessage
                          name="user_email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="column right-column">
                      <div className="field-container">
                        <label className="res-font-formlabel" htmlFor="purpose">
                          {t("contactUs>*Contact purpose")}
                        </label>
                        <Field
                          component={SelectField}
                          name="purpose"
                          options={getPurposeOptions(purposes)}
                          placeholder={t("contactUs>No Purpose Selected")}
                          className={
                            "res-font-formlabel" +
                            (errors.purpose && touched.purpose
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="purpose"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="field-container">
                        <label className="res-font-formlabel" htmlFor="message">
                          {t("contactUs>*Your message")}
                        </label>
                        <Field
                          className={
                            "textareafield" +
                            (errors.message && touched.message
                              ? " is-invalid"
                              : "")
                          }
                          name="message"
                          as={CharacterCountField}
                          display="textarea"
                          placeholder={t(
                            "contactUs>Min 3 characters and max 500 characters"
                          )}
                          maxLength={500}
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="reCaptcha-container">
                        <IsHuman
                          setToken={setToken}
                          className="reCaptcha-right"
                          errorMsgElem={document.getElementById(
                            "reCaptcha-error"
                          )}
                        />
                      </div>
                      <div id="reCaptcha-error"></div>
                      <div className="send-button-container">
                        <button
                          className="send-button button-black button-w130"
                          type="submit"
                        >
                          {t("contactUs>Send")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ContactUs;
