import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import NeedHelpModal from "../NeedHelpModal";

const ContactUsFormSentContent = (props) => {
  ContactUsFormSentContent.propTypes = {
    closeModalContactUsFormSent: PropTypes.func,
  };

  const { t } = useTranslation();
  const closeModals = () => {
    props.closeModalContactUsFormSent();
  };

  const [lgShow, setLgShow] = useState(false);
  const [content, setContent] = useState(null);

  const openModalNeedHelp = () => {
    setLgShow(true);
    setContent(<NeedHelpModal />);
  };

  return (
    <>
      <div>
        <Modal.Header closeButton>
          <Modal.Title id="contact-us-form-sent"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalbody">
          <div className="modal-width">
            <div className="login-logo">
              <div className="logotext">
                <span className="life">Life</span>
                <span className="learning">Learnings</span>
              </div>
            </div>
            <div>
              <div className="contactussentform-container">
                <div className="padding-container res-font-extra-header header-text">
                  <label>{t("contactUs>Thank you for contacting Life Learnings!")}</label>
                </div>
                <div>
                  <div className="res-font-regular-medium">
                    <div>{t("contactUs>We will get back to you as soon as possible.")}</div>
                    <div>
                      {t("contactUs>Please also review")}
                      &nbsp;
                      <button
                        className="button button-transparent"
                        onClick={() => openModalNeedHelp()}
                      >
                        {t("contactUs>Need Help")}
                      </button>
                      &nbsp;
                      {t("contactUs>page")}
                    </div>
                    <div>{t("contactUs>for additional guidance.")}</div>
                  </div>
                  <div className="padding-container res-font-regular-medium res-font-extra">
                    <div>{t("contactUs>Thank you,")}</div>
                    <div>{t("contactUs>Life Learnings Team")}</div>
                  </div>
                  <div className="close-button-container">
                    <button
                      className="close-button-text button-black button-w130"
                      onClick={() => closeModals()}
                    >
                      {t("contactUs>Close")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal
      size="xl"
      show={lgShow}
      onHide={() => setLgShow(false)}
      animation={false}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      {content}
    </Modal>
  </>
  );
};
export default ContactUsFormSentContent;
