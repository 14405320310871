import React from "react";
import { useTranslation } from "react-i18next";

const TermsOfUse = () => {
  const { t } = useTranslation('termsOfUse');

  return (
    <div className="terms-of-use-container">
      <div className="logo-container">
        <div className="logotext">
          <span className="life">Life</span>
          <span className="learning">Learnings</span>
        </div>
      </div>
      <div className="modal-text-container">
        <div className="modal-header-text">
          <h1 className="res-font-extra-header">{t("Terms of Use")}</h1>
        </div>
        <div className="terms-text res-font-regular-medium">
          <p>
            {t(
              "Life Learnings LLC, a Florida limited liability company, welcomes you \
to our website (the “Site”) and the applications and services \
available from us, through the Site or other platforms (collectively \
with the Site, the “Services”). Your use of the Site and the Services \
are governed by these Terms of Use (these “Terms”). Any time you \
browse the Site or use the Services in any way, you agree to be bound \
by these Terms. If you don’t agree to these Terms, do not use the Site \
or the Services."
            )}{" "}
            <br />
            <br />
            {t(
              "Your use of the Services is also subject to our Privacy Policy, which \
is located on the Site, as well as any policies and procedures we \
publish from time to time (collectively, the “Policies”). We reserve \
the right to modify these Terms at any time, with such changes \
becoming effective when we post the modified Terms to the Site. We \
also reserve the right to make any changes to the Site and Services in \
any manner and to deny or terminate your access to the Site and \
Services, even if you have an Account, in our sole discretion."
            )}{" "}
            <br />
            <br />
            {t(
              "Each time you use the Site or the Services, the then-current version \
of the Terms will apply. If you use the Site or the Services after a \
modification of these Terms, you agree to be bound by the Terms as \
modified."
            )}{" "}
            <br />
            <br />
            <strong>
              {t(
                "These Terms contain important information regarding your rights with \
respect to the Site and the Services, including your relationship with \
us, and include an arbitration provision that may limit your ability \
to pursue claims against us in court. Please read them carefully and \
review them regularly."
              )}
            </strong>
          </p>
          <p className="res-font-formlabel">1. {t("Eligibility")}.</p>
          <p>
            {t(
              "When you use the Services, you represent that you are (i) at least the \
age of majority in the jurisdiction where you reside or (ii) if you \
have not reached the age of majority in the jurisdiction where you \
reside, that you have received permission to use the Services from \
your parent or legal guardian."
            )}{" "}
            <br />
            <br />
            {t(
              "You represent that any information you submit to us when using the \
Services is accurate, truthful, and current. You also represent that \
your use of the Services does not violate any applicable law or \
regulation."
            )}
          </p>
          <p className="res-font-formlabel">
            2. {t("Registration & Account")}.
          </p>
          <p>
            {t(
              "Certain of the Services or portions of the Site may require you to \
register for an account (“Account”), becoming a “Registered User”. As \
part of the Account creation process, you may be asked to provide a \
username and password unique to the Account (“Login Information”). You \
are responsible for the confidentiality and use of your Login \
Information and agree not to transfer or disclose your Login \
Information to any third party other than an individual with express \
authority to act on your behalf. If you suspect any unauthorized use \
of your Account, you agree to notify us immediately. You are solely \
responsible for any activities occurring under your Account. You have \
no ownership right to your Account. If you are registering an Account \
as the authorized user of an organization, that organization may have \
administrator rights to access your account and any information \
provided under your Account."
            )}
          </p>
          <p className="res-font-formlabel">
            3. {t("Organizational Accounts")}.
          </p>
          <p>
            {t(
              "If you register for the Services on behalf of an organization, you may \
grant access to the Services to certain authorized users, subject to \
the limits of any plan for which you enroll. We may require that each \
authorized user have unique Login Information. When registering for an \
Account and accessing the Services, you represent or warrant that the \
information you enter for your organization is correct. You \
acknowledge and agree that (i) the organizational account owner is \
responsible for all activity under authorized user accounts and (ii) \
organizational administrators may have access to all activity/data \
under all authorized users’ accounts."
            )}
          </p>
          <p className="res-font-formlabel">
            4. {t("Permitted Uses/License")}.
          </p>
          <p>
            {t(
              "You are authorized to access the Site for the sole purpose of viewing \
and using the Services on your computer or device. We authorize you to \
copy materials from the Services to your hard drive solely for the \
purpose of viewing and using the Services on your computer."
            )}
            <br />
            <br />
            {t(
              "You may not decompile, disassemble, rent, lease, loan, sell, \
sublicense, or create derivative works from the Site, the Services, or \
any data thereon. You may not use any robot, spider, or other \
automatic device or manual process to monitor or copy the Site or its \
content without our prior written permission. Your failure to abide by \
these conditions will immediately terminate your right to access the \
Site or to use the Services and may violate our intellectual property \
rights or the intellectual property rights of third parties."
            )}
          </p>
          <p className="res-font-formlabel">
            5. {t("Location-based Services")}.
          </p>
          <p>
            {t(
              "Some of the Services may require that location functionality be \
enabled on the relevant device in order to work properly. You \
acknowledge and agree that if location permissions and functionalities \
are not enabled on the device with which you access the Services, the \
Services may not work appropriately or at all. We will use any \
location information we receive from you in accordance with our \
Privacy Policy."
            )}
          </p>
          <p className="res-font-formlabel">6. {t("Third Party Sites")}.</p>
          <p>
            {t(
              "The Site may contain links to websites we do not operate, control, or \
maintain (“Third Party Websites”). We do not endorse any Third Party \
Websites, and we make no representation or warranty in any respect \
regarding the Third Party Websites. Any links to Third Party Websites \
on the Site are provided solely for your convenience. If you do access \
any Third Party Websites, you do so at your own risk and waive any and \
all claims against us regarding the Third Party Websites or our links \
thereto."
            )}
          </p>
          <p className="res-font-formlabel">7. {t("Data")}</p>
          <p>
            {t(
              "You agree that we have the right to collect and analyze data and other \
information relating to the provision, use and performance of various \
aspects of the Site and Services, and related systems (for example, \
anonymous and aggregated information concerning user behavior and use \
of the Services), and we will be free (during and after the term \
hereof) to (i) use such information and data to improve and enhance \
the Site Services and for other development, diagnostic and corrective \
purposes in connection with the Site and Services and other of our \
offerings, and (ii) disclose such data solely in aggregate or other \
de-identified form in connection with its business."
            )}
          </p>
          <p className="res-font-formlabel">8. {t("User Conduct")}</p>
          <p>
            {t(
              "You agree not to use the Site or the Services to take any action or \
actions that (including with respect to any User Content): (i) are \
patently offensive in any manner (as determined in our sole \
discretion), (ii) involve commercial activities without our prior \
written consent, such as contests or sweepstakes, (iii) are contrary \
to our public image, goodwill, or reputation, (iv) infringe on our or \
any third party’s intellectual property rights, (v) violate any law or \
any third party’s legal rights, or (vi) “frame” or “mirror” any part \
of the Site without our prior written consent."
            )}
          </p>
          <p className="res-font-formlabel">9. {t("User Content Generally")}</p>
          <p>
            {t(
              "When you post content and information to the Site or in connection \
with the Services (“User Content”), you represent and warrant to us \
that (i) you own or have rights to use the User Content, (ii) the \
posting of the User Content does not violate any rights of any person \
or entity, and (iii) you have no agreement with or obligations to any \
third party that would prohibit your use of the Site or Services in \
the manner so used. You agree to pay all royalties, fees, and any \
other monies owing to any person or entity by reason of any User \
Content posted by you to the Site or through the Services. You \
acknowledge and agree that we may, in our sole discretion, remove User \
Content at any time and for any reason, or for no reason at all. You \
agree not to include any personally-identifiable information about \
yourself or any other person in any User Content."
            )}{" "}
            <br />
            <br />
            {t(
              "By posting User Content, you give us and our affiliates a perpetual, \
nonexclusive, irrevocable, royalty-free, sublicensable and \
transferable worldwide license to all intellectual property rights you \
own or control to use, transmit, reproduce, commercialize, distribute, \
modify, create derivative works from, and otherwise exploit such User \
Content for any and all purposes and without further notice to you, \
attribution, and without the requirement of any permission or payment \
to you or any other person or entity, except as otherwise expressly \
provided in Section 10. You also authorize and appoint us as your \
attorney in fact and agent with full power to enter into and execute \
any document or undertake any action we may consider appropriate to \
use or enforce the grant of rights and waivers set forth in these \
Terms."
            )}
          </p>
          <p className="res-font-formlabel">
            10. {t("Content-Sharing Platform; Contributions")}.
          </p>
          <p>
            {t(
              "The Services include a content-sharing platform (the “Platform”) \
whereby content-creating users (“Creators”) can create and share \
certain User Content with other users (“Content Consumers”). If you \
are a Content Consumer, you may choose to make a monetary \
donation/contribution to the Creator of certain User Content (a \
“Contribution”). Contributions will be made using a third-party \
payment service provider, and you will need to agree to that service \
provider’s terms and conditions prior to making a Contribution. \
Contributions are not tax-deductible."
            )}{" "}
            <br />
            <br />
            {t(
              "If you make a Contribution, we will keep 60% of the Contribution as a \
platform fee and forward the remaining 40% to the Creator, so long as \
the Creator has complied with the requirements of this Section in \
order to receive payment. As the contributing Content Consumer, you \
acknowledge and agree that we do not represent or warrant that any \
Creator will be eligible to receive any Contribution."
            )}
            <br />
            <br />
            {t(
              "If you are a Creator, you must maintain (i) an Account and (ii) an \
active payment method (which may depend on the availability of our \
payment processor where you live) in order to be eligible to receive \
any portion of Contributions made with respect to your User Content. \
You acknowledge and agree that if you are not eligible to receive \
Contributions at a time when a Contribution is made with respect to \
your User Content, that we will retain the entirety of all \
Contributions with no further liability to you."
            )}
            <br />
            <br />{" "}
            {t(
              "You acknowledge and agree that the Platform is provided solely \
for Creators to share User Content with Content Consumers and that \
cannot and do not assess the accuracy or truth of any User Content."
            )}
          </p>
          <p className="res-font-formlabel">
            11. {t("Copyright Infringement")}.
          </p>
          <p>
            {t(
              "We respect the intellectual property rights of others. The Digital \
Millennium Copyright Act of 1998 (the “DMCA”) provides a complaint \
procedure for copyright owners who believe that website material \
infringes their rights under U.S. copyright law. If you believe that \
your work has been improperly copied and posted on the website, please \
provide us with the following information: (i) name, address, \
telephone number, email address and an electronic or physical \
signature of the copyright owner or of the person authorized to act on \
his/her behalf; (ii) a description of the copyrighted work that you \
claim has been infringed; (iii) a description of where on the Site the \
material that you claim is infringing is located; (iv) a written \
statement that you have a good faith belief that the disputed use is \
not authorized by the copyright owner, its agent, or the law; and (v) \
a statement by you, made under penalty of perjury, that the above \
information in your notice is accurate and that you are the copyright \
owner or authorized to act on the copyright owner’s behalf. These \
requirements must be followed to give us legally sufficient notice of \
infringement. Send copyright infringement complaints to the following \
email address: withgratitude@lifelearnings.com. We suggest that you \
consult your legal advisor before filing a DMCA notice with our \
copyright agent. There can be penalties for false claims under the \
DMCA."
            )}
          </p>
          <p className="res-font-formlabel">12. {t("Warranty Disclaimer")}.</p>
          <p>
            <strong>
              {t(
                "You agree that the Services are available on an “as is” basis, \
without any warranty, and that you use the Services at your own \
risk. We disclaim, to the maximum extent permitted by law, any and \
all warranties, whether express or implied, including, without \
limitation, (a) warranties of merchantability or fitness for a \
particular purpose, (b) warranties against infringement of any third \
party intellectual property or proprietary rights, (c) warranties \
relating to delays, interruptions, errors, or omissions in the \
Services or on the Site, (d) warranties relating to the accuracy or \
correctness of data on the Services, and (e) any other warranties \
otherwise relating to our performance, nonperformance, or other acts \
or omissions."
              )}
            </strong>
            <br />
            <br />
            <strong>
              {t(
                "We do not warrant that the Site or the Services will operate \
error-free or that the Site is free of computer viruses and/or other \
harmful materials. If your use of the Site or the Services results \
in the need for servicing or replacing equipment or data, we are not \
responsible for any such costs."
              )}
            </strong>
            <br />
            <br />
            {t(
              "Some jurisdictions do not allow the exclusion or limitation of certain \
categories of damages or implied warranties; therefore, the above \
limitations may not apply to you. In such jurisdictions, our liability \
is limited to the greatest extent permitted by law."
            )}
          </p>
          <p className="res-font-formlabel">
            13. {t("Limitation of Liability")}.
          </p>
          <p>
            <strong>
              {t(
                "Any liability we have to you in connection with these Terms, under \
any cause of action or theory, is strictly limited to, in aggregate \
for all violations, $100. Without limiting the previous sentence, in \
no event shall we or any of our affiliates be liable to you for any \
indirect, special, incidental, consequential, punitive, or exemplary \
damages arising out of or in connection with, these Terms. The \
foregoing limitations apply whether the alleged liability is based \
on contract, tort, negligence, strict liability, or any other basis, \
even if we or our affiliates have been advised of the possibility of \
such damages."
              )}
            </strong>
            <br />
            <br />
            <strong>
              {t(
                "You agree to indemnify and hold us harmless for any breach of \
security or any compromise of your Account."
              )}
            </strong>
            <br />
            <br />
            {t(
              "Some jurisdictions do not allow the exclusion or limitation of \
incidental or consequential; therefore, the above limitations may not \
apply to you. In such jurisdictions, our liability is limited to the \
greatest extent permitted by law."
            )}
          </p>
          <p className="res-font-formlabel">14. {t("Indemnification")}.</p>
          <p>
            {t(
              "You agree to indemnify and hold harmless us, our affiliates and our \
and their officers, directors, partners, agents, and employees from \
and against any loss, liability, claim, or demand, including \
reasonable attorneys’ fees (collectively, “Claims”), made by any third \
party due to or arising out of your use of the Site and Services in \
violation of these Terms, any breach of the representations and \
warranties you make in these Terms, or your User Content. You agree to \
be solely responsible for defending any Claims against or suffered by \
us with counsel subject to our reasonable approval and further subject \
to our right to participate with counsel of our own choosing."
            )}
          </p>
          <p className="res-font-formlabel">
            15. {t("Electronic Signatures and Notices")}.
          </p>
          <p>
            {t(
              "Certain activities on the Services may require you to make an \
electronic signature. You understand and accept that an electronic \
signature has same legal rights and obligations as a physical \
signature."
            )}
            <br />
            <br />
            {t(
              "If you have an Account, you agree that we may provide you any and all \
required notices electronically through your Account or other \
electronic means. You agree that we are not responsible for any \
delivery fees charged to you as a result of your receipt of our \
electronic notices."
            )}
          </p>
          <p className="res-font-formlabel">16. {t("Governing Law")}.</p>
          <p>
            {t(
              "These Terms are governed by Florida law, without giving effect to \
conflicts of law principles. You agree that, to the extent applicable \
and expressly subject to the dispute resolution provisions below, to \
submit to the exclusive jurisdiction of the state and federal courts \
located in Palm Beach County, Florida in circumstances where these \
Terms permit litigation in court."
            )}
          </p>
          <p className="res-font-formlabel">17. {t("Dispute Resolution")}</p>
          <p>
            <strong>
              {t(
                "Please read this section carefully. It contains procedures for \
mandatory binding arbitration and a class action waiver."
              )}
            </strong>
            <br />
            <br />
            <i>
              {t("Notice Requirement and Informal Dispute Resolution")}.
            </i>{" "}
            {t(
              "Before \
          either we or you may seek arbitration, the party seeking arbitration \
must send the other party a written Notice of Dispute (“Notice”) \
describing the nature and basis of the claim or dispute and the \
requested relief. A Notice to us should be sent to: Life Learnings \
LLC, 12996 Anthorne Ln, Boynton Beach, Florida 33436. After the Notice \
is received, you and we may attempt to resolve the claim or dispute \
informally. If we do not resolve the claim or dispute within thirty \
(30) days after the Notice is received, either party may begin an \
arbitration proceeding. The amount of any settlement offer made by any \
party may not be disclosed to the arbitrator until after the \
arbitrator has determined the amount of the award, if any, to which \
either party is entitled."
            )}
            <br />
            <br />
            <i>{t("Arbitration Rules")}.</i>{" "}
            {t(
              "Arbitration shall be initiated through the \
American Arbitration Association (“AAA”), an established alternative \
dispute resolution provider (“ADR Provider”) that offers arbitration \
as set forth in this section. If AAA is not available to arbitrate, \
the parties shall agree to select an alternative ADR Provider. The \
rules of the ADR Provider shall govern all aspects of the arbitration, \
including but not limited to the method of initiating and/or demanding \
arbitration, except to the extent such rules are in conflict with the \
Terms. The AAA Commercial Arbitration Rules (the “Arbitration Rules”) \
governing the arbitration are available online at www.adr.org or by \
calling the AAA at 1-800-778-7879. The arbitration shall be conducted \
by a single, neutral arbitrator. Any claims or disputes where the \
total amount of the award sought is less than Ten Thousand U.S. \
Dollars (US $10,000.00) shall be resolved through binding \
non-appearance-based arbitration. For claims or disputes where the \
total amount of the award sought is Ten Thousand U.S. Dollars (US \
$10,000.00) or more, the right to a hearing will be determined by the \
Arbitration Rules. Any hearing will be held in Palm Beach County, \
Florida, unless the parties agree otherwise. Any judgment on the award \
rendered by the arbitrator may be entered in any court of competent \
jurisdiction. Each party shall bear its own costs (including \
attorney’s fees) and disbursements arising out of the arbitration and \
shall pay an equal share of the fees and costs of the ADR Provider."
            )}
            <br />
            <br />
            <i>
              {t("Additional Rules for Non-Appearance Based Arbitration")}.
            </i>{" "}
            {t(
              "The \
arbitration shall be conducted by telephone, online and/or based \
solely on written submissions; the specific manner shall be chosen by \
the party initiating the arbitration. The arbitration shall not \
involve any personal appearance by the parties or witnesses unless \
otherwise agreed by the parties."
            )}
            <br />
            <br />
            <i>{t("Time Limits")}.</i>{" "}
            {t(
              "If either you or we pursue arbitration, the \
arbitration action must be initiated and/or demanded within the \
statute of limitations (i.e., the legal deadline for filing a claim) \
and within any deadline imposed under the AAA Rules for the pertinent \
claim."
            )}
            <br />
            <br />
            <i>{t("Authority of Arbitrator")}.</i>{" "}
            {t(
              "If arbitration is initiated, the \
arbitrator will decide the rights and liabilities, if any, of the \
parties involved, and the dispute will not be consolidated with any \
other matters or joined with any other cases or parties. The \
arbitrator shall have the authority to grant motions dispositive of \
all or part of any claim. The arbitrator shall have the authority to \
award monetary damages, and to grant any non-monetary remedy or relief \
available to an individual under applicable law, the Arbitration \
Rules, and these Terms. The arbitrator shall issue a written award and \
statement of decision describing the essential findings and \
conclusions on which the award is based, including the calculation of \
any damages awarded. The arbitrator has the same authority to award \
relief on an individual basis that a judge in a court of law would \
have. The award of the arbitrator is final and binding upon you and \
us."
            )}
            <br />
            <br />
            <i>{t("Waiver of Jury Trial")}.</i>{" "}
            {t(
              "THE PARTIES HEREBY WAIVE THEIR \
CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN \
FRONT OF A JUDGE OR A JURY, instead electing that all claims and \
disputes shall be resolved by arbitration under these terms. \
Arbitration procedures are typically more limited, more efficient and \
less costly than rules applicable in a court and are subject to very \
limited review by a court. In the event any litigation should arise \
between you and us in any state or federal court in a suit to vacate \
or enforce an arbitration award or otherwise, YOU AND WE WAIVE ALL \
RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved \
by a judge."
            )}{" "}
            <br />
            <br />
            <i>{t("Waiver of Class or Consolidated Actions")}.</i>{" "}
            {t(
              "ALL CLAIMS AND \
DISPUTES WITHIN THE SCOPE OF THIS SECTION 17 MUST BE ARBITRATED OR \
LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS \
OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR \
CONSOLIDATED WITH THOSE OF ANY OTHER USER."
            )}
            <br />
            <br />
            <i>{t("Confidentiality")}.</i>{" "}
            {t(
              "All aspects of the arbitration proceeding, \
including but not limited to the award of the arbitrator and \
compliance therewith, shall be strictly confidential. You agree to \
maintain confidentiality unless otherwise required by law. This \
paragraph shall not prevent a party from submitting to a court of law \
any information necessary to enforce this Section 17, to enforce an \
arbitration award, or to seek injunctive or equitable relief."
            )}
            <br />
            <br />
            <i>{t("Severability")}.</i>{" "}
            {t(
              "If any part or parts of this Section 17 are found \
under the law to be invalid or unenforceable by a court of competent \
jurisdiction, then such specific part or parts shall be of no force \
and effect and shall be severed and the remainder of this Section 17 \
shall continue in full force and effect."
            )}
            <br />
            <br />
            <i>{t("Right to Waive")}.</i>{" "}
            {t(
              "Any or all of the rights and limitations set \
forth in this Section 17 may be waived by the party against whom the \
claim is asserted. Such waiver shall not waive or affect any other \
portion of this Section 17."
            )}
            <br />
            <br />
            <i>{t("Survival of Agreement")}.</i>{" "}
            {t(
              "This Section 17 will survive the \
termination of your relationship with us."
            )}
            <br />
            <br />
            <i>{t("Small Claims Court")}.</i>{" "}
            {t(
              "Notwithstanding the foregoing, either you \
or we may bring an individual action in small claims court."
            )}
            <br />
            <br />
            <i>{t("Emergency Equitable Relief")}.</i>{" "}
            {t(
              "Notwithstanding the foregoing, \
either party may seek emergency equitable relief before a state or \
federal court in order to maintain the status quo pending arbitration. \
A request for interim measures shall not be deemed a waiver of any \
other rights or obligations under this Section 17."
            )}
            <br />
            <br />
            <i>{t("Claims Not Subject to Arbitration")}.</i>{" "}
            {t(
              "Notwithstanding the \
foregoing, claims of defamation, violation of the Computer Fraud and \
Abuse Act, and infringement or misappropriation of our patent, \
copyright, trademark or trade secrets rights shall not be subject to \
this Section 17."
            )}
          </p>
          <p className="res-font-formlabel">
            18. {t("Notice for California Users")}.
          </p>
          <p>
            {t(
              "Under California Civil Code Section 1789.3, California Website users \
are entitled to the following specific consumer rights notice: The \
Complaint Assistance Unit of the Division of Consumer Services of the \
California Department of Consumer Affairs may be contacted in writing \
at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or \
by telephone at (800) 952-5210."
            )}
          </p>
          <p className="res-font-formlabel">19. {t("Miscellaneous")}.</p>
          <p>
            {t(
              "We may assign, transfer, delegate, or otherwise hypothecate our rights \
under these Terms in our sole discretion. If we fail to enforce a \
provision of these Terms, you agree that such a failure does not \
constitute a waiver to enforce the provision (or any other provision \
hereunder). If any provision of these Terms is held or made invalid, \
the invalidity does not affect the remainder of these Terms. We \
reserve all rights not expressly granted in these Terms and disclaim \
all implied licenses."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
