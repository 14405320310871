import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyTable1 from './PrivacyPolicyTable1.js';
import PrivacyPolicyTable2 from './PrivacyPolicyTable2.js';

const PrivacyPolicy = () => {
  const [lgShow, setLgShow] = useState(false);
  const [content] = useState(null);

  const { t } = useTranslation('privacyPolicy');

  return (
    <div className="terms-of-use-container">
      <div className="logo-container">
        <div className="logotext">
          <span className="life">Life</span>
          <span className="learning">Learnings</span>
        </div>
      </div>
      <div className="modal-text-container">
        <div className="modal-header-text">
          <h1 className="res-font-extra-header">{t('Privacy Policy')}</h1>
        </div>
        <div className="res-font-formlabel mb-3">
          <span
            className="button-transparent button-w220 clickable-span-text"
            onClick={ () => { window.cookiehub.openSettings() }}
          >
            {t('Our Cookie Policy')}
          </span>
        </div>
        <div className="terms-text res-font-regular-medium">
          <p className="res-font-formlabel">{t('Introduction')}.</p>
          <p>
            {t(
              'Welcome to our privacy policy. We are Life Learnings, LLC, a Florida limited liability company \
(“we”, or “us”). We are responsible for this website.'
            )}
            <br />
            {t(
              'We respect your privacy and are committed to protecting your personal data. \
This privacy policy aims to give you information on how we collect and processes \
your personal data through your use of this website, including any data \
you may provide through this website when you sign up to our service or purchase a product. '
            )}
            <br />
            {t(
              'This website is not intended for children and we do not knowingly collect \
data relating to children. If you believe we have information relating to any \
individual under the age of 13, please notify us using the methods listed \
in this policy and we will remove such information as soon as possible.'
            )}
          </p>
          <p className="res-font-formlabel">{t('Who We Are')}.</p>
          <ul>
            <li>{t('Full name of legal entity: Life Learnings, LLC')}</li>
            <li>
              {t(
                'Name or title of Data Protection Manager: Chief Executive Officer'
              )}
            </li>
            <li>{t('Email address: withgratitude@lifelearnings.com')}</li>
          </ul>
          <p>
            {t(
              'Our website may include links to third-party websites, plug-ins and applications. \
Clicking on those links or enabling those connections may allow third parties \
to collect or share data about you. We do not control these third-party websites \
and are not responsible for their privacy statements. When you leave our website, \
we encourage you to read the privacy policy of every website you visit.'
            )}
          </p>
          <p className="res-font-formlabel">{t('What We Collect')}.</p>
          <p>
            {t(
              'We act as a data “controller,” meaning that we collect certain Personal Information \
from you as you engage the website, our services, or contact us. Data privacy \
and protection laws across the United States and the European Union (“Privacy Laws”) \
apply to how we collect that information and what we use it for. \
To help us comply with Privacy Laws, and because we care about being transparent with you, \
below is a chart detailing what sort of personal information we collect about you, \
how we collect it, and why. In some cases, we may collect different information from you \
based on the type of relationship we have with you, and in such cases the differences are noted.'
            )}
          </p>
          {/* flex table here */}
          <PrivacyPolicyTable1 />
          <p className="res-font-formlabel">{t('Contact details')}.</p>
          <p>
            {t(
              'You have the right to make a complaint at any time to the supervisory authority for data protection issues \
in your jurisdiction. We would, however, appreciate the chance to deal with your concerns before \
you approach such authorities so please contact us in the first instance. '
            )}
          </p>
          <p className="res-font-formlabel">
            {t(
              'Changes to the privacy policy and your duty to inform us of changes'
            )}
            .
          </p>
          <p>
            {t(
              'It is important that the personal data we hold about you is accurate and current. \
Please keep us informed if your personal data changes during your relationship with us.'
            )}
          </p>
          <p className="res-font-formlabel">{t('Third-party links')}.</p>
          <p>
            {t(
              'This website may include links to third-party websites, plug-ins and applications. \
Clicking on those links or enabling those connections may allow third parties \
to collect or share data about you. We do not control these third-party websites \
and are not responsible for their privacy statements. When you leave our website, \
we encourage you to read the privacy policy of every website you visit.'
            )}
          </p>
          <p className="res-font-formlabel">
            1. {t('The data we collect about you')}
          </p>
          <p>
            {t(
              'Personal data, or personal information, means any information about an individual \
from which that person can be identified. It does not include data where the identity \
has been removed (anonymous data). We have grouped these different kinds of personal data together as follows:'
            )}
          </p>
          <ul>
            <li>
              {t(
                'Identity Data may include first name, last name, and other demographic data that you provide to us.'
              )}
            </li>
            <li>{t('Contact Data includes your email address.')}</li>
            <li>
              {t(
                'Financial Data includes bank account and payment card details. Please note that we use Stripe, \
a third-party payment processor, to process payments. Stripe will collect, use, \
and process your information in accordance with its privacy policies, which you may review here. \
Stripe’s services in Europe are provided by a Stripe affiliate, Stripe Payments Europe Limited, \
an entity located in Ireland.'
              )}
            </li>
            <li>
              {t(
                'Transaction Data includes details about payments to and from you and other details of products \
and services you have purchased from us.'
              )}
            </li>
            <li>
              {t(
                'Technical Data includes internet protocol (IP) address, your login data, browser type and version, \
time zone setting and location, browser plug-in types and versions, operating system and platform \
and other technology on the devices you use to access this website.'
              )}
            </li>
            <li>
              {t(
                'Profile Data includes your username and password, purchases made by you, your preferences, \
feedback and survey responses.'
              )}
            </li>
            <li>
              {t(
                'Embedded Data covers the personal data about you which may be added to the services available \
on our website (“Services”) by a third party.'
              )}
            </li>
            <li>
              {t(
                'Usage Data includes information about how you use our website, products and services.'
              )}
            </li>
            <li>
              {t(
                'Marketing and Communications Data includes your preferences in receiving marketing from us \
and our third parties and your communication preferences.'
              )}
            </li>
          </ul>
          <p>
            {t(
              'We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. \
Aggregated Data may be derived from your personal data but is not considered personal data in law \
as this data does not directly or indirectly reveal your identity. For example, \
we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. \
However, if we combine or connect Aggregated Data with your personal data so that it can directly \
or indirectly identify you, we treat the combined data as personal data which will be used \
in accordance with this privacy policy.'
            )}
          </p>
          <p>
            {t(
              'We do not collect any Special Categories of Personal Data about you (this includes details \
about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, \
political opinions, trade union membership, information about your health and genetic \
and biometric data). Nor do we collect any information about criminal convictions and offences.'
            )}
          </p>
          <p className="res-font-formlabel">
            {t('If you fail to provide personal data')}
          </p>
          <p>
            {t(
              'Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail \
to provide that data when requested, we may not be able to perform the contract we have or are trying to enter \
into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product \
or service you have with us, but we will notify you if this is the case at the time.'
            )}
          </p>
          <p className="res-font-formlabel">
            2. {t('How is your personal data collected?')}
          </p>
          <p>
            {t(
              'We use different methods to collect data from and about you are including through:'
            )}
          </p>
          <p>
            {t(
              'Direct interactions. You may give us your Identity, Contact, and Financial Data by filling in forms \
or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide \
when you: create an account on our website; use our services; request marketing to be sent to you; or give us feedback.'
            )}
          </p>
          <p>
            {t(
              'Automated technologies or interactions. As you interact with our website, we may automatically collect \
Technical Data about your equipment, browsing actions and patterns. We may collect this personal data \
by using cookies, server logs and other similar technologies. We may also receive Technical Data about you \
if you visit other websites employing our cookies.'
            )}
          </p>
          <p>
            {t(
              'Third parties or publicly available sources. We may receive personal data about you \
from various third parties and public sources as set out below:'
            )}
          </p>
          <ul>
            <li>
              {t('Technical Data from the following parties')}:
              <ul>
                <li>{t('Analytics providers')}</li>
                <li>{t('Advertising networks')}</li>
                <li>{t('Search information providers')}</li>
              </ul>
            </li>
            <li>
              {t(
                'Contact, Financial and Transaction Data from providers of technical, payment and delivery services'
              )}
            </li>
            <li>
              {t('Identity and Contact Data from data brokers or aggregators')}
            </li>
            <li>
              {t('Identity and Contact Data from publicly availably sources')}
            </li>
            <li>
              {t(
                'Contact, Identity, and Embedded Data from other users of our Services'
              )}
            </li>
          </ul>
          <p className="res-font-formlabel">
            3. {t('How we use your personal data')}
          </p>
          <p>
            {t(
              'We will only use your personal data when the law allows us to. Most commonly, we will use your personal data \
in the following circumstances:'
            )}
          </p>
          <ul>
            <li>
              {t(
                'Where we need to perform the contract we are about to enter into or have entered into with you'
              )}
              .
            </li>
            <li>
              {t(
                'Where it is necessary for our legitimate interests (or those of a third party) and your interests \
and fundamental rights do not override those interests'
              )}
              .
            </li>
            <li>
              {t(
                'Where we need to comply with a legal or regulatory obligation'
              )}
              .
            </li>
          </ul>
          <p>
            {t(
              'Generally, we do not rely on consent as a legal basis for processing your personal data \
other than in relation to sending third party direct marketing communications to you \
via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.'
            )}
          </p>
          <p className="res-font-formlabel">
            {t('Purposes for which we will use your personal data')}
          </p>
          <p>
            {t(
              'We have set out below, in table format, a description of all the ways we plan to use your personal data, \
and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are \
where appropriate.'
            )}
            <br />
            {t(
              'Note that we may process your personal data for more than one lawful ground depending on the specific purpose \
for which we are using your data. Please contact us if you need details about the specific legal ground \
we are relying on to process your personal data where more than one ground has been set out in the table below.'
            )}
          </p>
          <PrivacyPolicyTable2 />
          <p className="res-font-formlabel">{t('Marketing')}</p>
          <p>
            {t(
              'We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.'
            )}
          </p>
          <p className="res-font-formlabel">
            {t('Promotional offers from us')}
          </p>
          <p>
            {t(
              'We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think \
you may want or need, or what may be of interest to you. This is how we decide \
which products, services and offers may be relevant for you (we call this marketing).'
            )}
            <br />
            {t(
              'You will receive marketing communications from us if you have requested information from us \
or purchased goods or services from us and, in each case, you have not opted out of receiving that marketing.'
            )}
          </p>
          <p className="res-font-formlabel">{t('Third-party marketing')}</p>
          <p>
            {t(
              'We will not share your personal data with any third party for marketing purposes.'
            )}
          </p>
          <p className="res-font-formlabel">{t('Opting out')}</p>
          <p>
            {t(
              'You can ask us or third parties to stop sending you marketing messages at any time by logging into the website \
and checking or unchecking relevant boxes to adjust your marketing preferences or \
by following the opt-out links on any marketing message sent to you.'
            )}
            <br />
            {t(
              'Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us \
as a result of a product/service purchase or other transaction.'
            )}
          </p>
          <p className="res-font-formlabel">{t('Cookies')}</p>
          <p>
            {t(
              'In order to improve our services and provide more convenient, relevant experiences to our customers, \
we and our agents may use “cookies,” “web beacons,” and similar devices to track your activities. \
A cookie is a small amount of data that is transferred to your browser by a web server \
and can only be read by the server that gave it to you. It functions as your identification card \
and enables us to record your passwords and preferences. It cannot be executed as code or deliver viruses. \
A web beacon is a small transparent .gif image that is embedded in an HTML page or email used to track \
when the page or email has been viewed. Most browsers are initially set to accept cookies, \
and most services that include similar devices are typically initially activated to collect data. \
You can set your browser to notify you when you receive a cookie, \
giving you the chance to decide whether or not to accept it.'
            )}
          </p>
          <p className="res-font-formlabel">{t('Change of purpose')}</p>
          <p>
            {t(
              'We will only use your personal data for the purposes for which we collected it, unless we reasonably \
consider that we need to use it for another reason and that reason is compatible with the original purpose. \
If you wish to get an explanation as to how the processing for the new purpose is compatible \
with the original purpose, please contact us.'
            )}
            <br />
            {t(
              'If we need to use your personal data for an unrelated purpose, \
we will notify you and explain the legal basis which allows us to do so.'
            )}
            <br />
            {t(
              'Please note that we may process your personal data \
without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.'
            )}
          </p>
          <p className="res-font-formlabel">
            4. {t('Disclosures of your personal data')}
          </p>
          <p>
            {t(
              'We may have to share your personal data with the parties set out below for the purposes \
set out in the table in section 3 above.'
            )}
          </p>
          <ul>
            <li>{t('Internal Third Parties as set out in the Glossary')}.</li>
            <li>{t('External Third Parties as set out in the Glossary')}.</li>
            <li>
              {t(
                'Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. \
Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, \
then the new owners may use your personal data in the same way as set out in this privacy policy'
              )}
              .
            </li>
          </ul>
          <p>
            {t(
              'We require all third parties to respect the security of your personal data and to treat \
it in accordance with the law. We do not allow our third-party service providers to use your \
personal data for their own purposes and only permit them to process your personal data for \
specified purposes and in accordance with our instructions.'
            )}
          </p>
          <p className="res-font-formlabel">5. {t('Data Processing')}</p>
          <p>
            {t(
              'In submitting any third-party personal data within the context of the Services (“Third-Party Data”), \
the parties agree that you shall be the Data Controller and you appoint us as a Data Processor of such data, \
for the purpose of providing the Services. To the extent that we are a “Data Processor” for you \
in respect of such Third-Party Data, we will:'
            )}
          </p>
          <ul className="alphabethical-list">
            <li>
              {t(
                'Implement appropriate technical and organizational measures to safeguard the Third-Party Data \
against any unauthorized or unlawful access, loss, destruction, theft, use or disclosure. \
We implement basic level security in accordance with United States law. If higher levels of security are required, \
please contact us'
              )}
              .
            </li>
            <li>
              {t(
                'Limit access to the Third-Party Data only to those employees who need to know it \
to enable the Processor to perform the Services, and shall take appropriate steps to ensure \
the reliability of those of our employees or subcontractors who have access to such Third-Party Data'
              )}
              .
            </li>
            <li>
              {t(
                'Only process the personal data as specified by this Privacy and in accordance with your instructions, \
and will not use the Third-Party Data for any purposes other than those related to the performance \
of the Services or pursuant to your written instructions'
              )}
              .
            </li>
            <li>
              {t(
                'Upon the expiry or termination of this Privacy Policy, or upon your request, cease any \
and all use of the Third-Party Data and destroy or return it to you'
              )}
              .
            </li>
            <li>
              {t(
                'Not disclose the Third-Party Data to any third-party without your prior written consent. \
Such consent is granted on, for example, your instruction to share a dashboard with third parties \
(public or within a restricted group)'
              )}
              .
            </li>
          </ul>
          <p>
            {t(
              'We as Data Processor may provide access to a subcontractor processor to any such Third-Party Data \
if we reasonably consider such access and processing necessary to the performance of the Services. \
In the event of such access and before the access takes place, we will ensure that an agreement \
with the third-party is in place which is sufficient to require it to treat personal data in accordance \
with the applicable provisions of this Agreement and applicable law. In particular, \
you authorize us to subcontract the provision of technology and commercial services to members of our corporate group.'
            )}
            <br />
            {t(
              'You represent and warrant that you have all the appropriate consents from data subjects \
whose personal data are submitted to us in the course of the provision of the Services.'
            )}
          </p>
          <p className="res-font-formlabel">
            6. {t('International transfers')}
          </p>
          <p>
            {t(
              'The processing of your personal data will involve transferring it outside the European Economic Area (“EEA”). \
In addition, many of our external third parties are based outside the EEA, so their processing of \
your personal data will involve a transfer of data outside the EEA.'
            )}
          </p>
          <p>
            {t(
              'Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is \
afforded to it by ensuring at least one of the following safeguards is implemented:'
            )}
          </p>
          <ul>
            <li>
              {t(
                'We will only transfer your personal data to countries that have been deemed to provide an adequate level \
of protection for personal data by the European Commission. For further details, \
see European Commission: Adequacy of the protection of personal data in non-EU countries'
              )}
              .
            </li>
            <li>
              {t(
                'Where we use certain service providers, we may use specific contracts approved by the European Commission \
which give personal data the same protection it has in Europe. For further details, see European Commission: \
Model contracts for the transfer of personal data to third countries'
              )}
              .
            </li>
          </ul>
          <p>
            {t(
              'Please contact us if you want further information on the specific mechanism used by us when transferring \
your personal data out of the EEA.'
            )}
          </p>
          <p className="res-font-formlabel">7. {t('Data security')}</p>
          <p>
            {t(
              'We have put in place appropriate security measures to prevent your personal data from being accidentally lost, \
used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data \
to those employees, agents, contractors and other third parties who have a business need to know. \
They will only process your personal data on our instructions and they are subject to a duty of confidentiality.'
            )}
          </p>
          <p>
            {t(
              'We have put in place procedures to deal with any suspected personal data breach and will notify you \
and any applicable regulator of a breach where we are legally required to do so.'
            )}
          </p>
          <p className="res-font-formlabel">8. {t('Data retention')}</p>
          <p className="res-font-formlabel">
            {t('How long will you use my personal data for')}?
          </p>
          <p>
            {t(
              'We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, \
including for the purposes of satisfying any legal, accounting, or reporting requirements.'
            )}
          </p>
          <p>
            {t(
              'To determine the appropriate retention period for personal data, we consider the amount, nature, \
and sensitivity of the personal data, the potential risk of harm from unauthorized use \
or disclosure of your personal data, the purposes for which we process your personal data \
and whether we can achieve those purposes through other means, and the applicable legal requirements.'
            )}
          </p>
          <p>
            {t(
              'In some circumstances you can ask us to delete your data: (see Request erasure below) \
for further in some circumstances we may anonymize your personal data \
(so that it can no longer be associated with you) for research or statistical purposes \
in which case we may use this information indefinitely without further notice to you.'
            )}
          </p>
          <p className="res-font-formlabel">9. {t('Your legal rights')}</p>
          <p>
            {t(
              'Under certain circumstances, you have rights under data protection laws in relation to your personal data.'
            )}
          </p>
          <ul>
            <li>
              {t(
                'Request access to your personal data (commonly known as a “data subject access request”). \
This enables you to receive a copy of the personal data we hold about you and to check that we are \
lawfully processing it.'
              )}
              .
            </li>
            <li>
              {t(
                'Request correction of the personal data that we hold about you. This enables you to have any incomplete \
or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data \
you provide to us'
              )}
              .
            </li>
            <li>
              {t(
                'Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there \
is no good reason for us continuing to process it. You also have the right to ask us to delete \
or remove your personal data where you have successfully exercised your right to object to processing (see below), \
where we may have processed your information unlawfully or where we are required to erase your personal data \
to comply with local law. Note, however, that we may not always be able to comply with your request of erasure \
for specific legal reasons which will be notified to you, if applicable, at the time of your request'
              )}
              .
            </li>
            <li>
              {t(
                'Object to processing of your personal data where we are relying on a legitimate interest \
(or those of a third party) and there is something about your particular situation which makes you \
want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. \
You also have the right to object where we are processing your personal data for direct marketing purposes. \
In some cases, we may demonstrate that we have compelling legitimate grounds to process your information \
which override your rights and freedoms'
              )}
              .
            </li>
            <li>
              {t(
                'Request restriction of processing of your personal data. This enables you to ask us to suspend the processing \
of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; \
(b) where our use of the data is unlawful but you do not want us to erase it; \
(c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise \
or defend legal claims; or (d) you have objected to our use of your data but we need to verify \
whether we have overriding legitimate grounds to use it'
              )}
              .
            </li>
          </ul>
          <p>
            {t(
              'If you wish to exercise any of the rights set out above, please contact us'
            )}
            .
          </p>
          <p className="res-font-formlabel">{t('No fee usually required')}</p>
          <p>
            {t(
              'You will not have to pay a fee to access your personal data (or to exercise any of the other rights). \
However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. \
Alternatively, we may refuse to comply with your request in these circumstances'
            )}
            .
          </p>
          <p className="res-font-formlabel">{t('What we may need from you')}</p>
          <p>
            {t(
              'We may need to request specific information from you to help us confirm your identity and ensure your right \
to access your personal data (or to exercise any of your other rights). This is a security measure to ensure \
that personal data is not disclosed to any person who has no right to receive it. We may also contact you \
to ask you for further information in relation to your request to speed up our response'
            )}
            .
          </p>
          <p className="res-font-formlabel">{t('Time limit to respond')}</p>
          <p>
            {t(
              'We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month \
if your request is particularly complex or you have made a number of requests. In this case, we will notify you \
and keep you updated'
            )}
            .
          </p>
          <p className="res-font-formlabel">10. {t('Governing Law')}</p>
          <p>
            {t(
              'The terms of this Privacy Policy are governed by and in accordance with the laws of the state of Florida, \
and, where applicable, the laws of the countries where our servers are located. You should not provide us \
with any Personal Information unless you consent to the application of United States law and, where applicable, \
Florida law, and to the use and disclosure of your information in accordance with the terms of this Privacy Policy'
            )}
            .
          </p>
          <p className="res-font-formlabel">11. {t('Glossary')}</p>
          <p className="res-font-formlabel">{t('LAWFUL BASIS')}</p>
          <p>
            {t(
              'Legitimate Interest means the interest of our business in conducting and managing our business to enable us \
to give you the best service/product and the best and most secure experience. We make sure we consider \
and balance any potential impact on you (both positive and negative) and your rights before we process \
your personal data for our legitimate interests. We do not use your personal data for activities \
where our interests are overridden by the impact on you (unless we have your consent or are otherwise required \
or permitted to by law). You can obtain further information about how we assess our legitimate interests \
against any potential impact on you in respect of specific activities by contacting us'
            )}
            .
          </p>
          <p>
            {t(
              'Performance of Contract means processing your data where it is necessary for the performance of a contract \
to which you are a party or to take steps at your request before entering into such a contract'
            )}
            .
          </p>
          <p>
            {t(
              'Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance \
with a legal or regulatory obligation that we are subject to'
            )}
            .
          </p>
          <p className="res-font-formlabel">{t('THIRD PARTIES')}</p>
          <p className="res-font-formlabel">{t('External Third Parties')}</p>
          <ul>
            <li>
              {t(
                'Service providers acting as processors who provide payment processing, IT and system administration services'
              )}
              .
            </li>
            <li>
              {t(
                'Professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, \
insurance and accounting services'
              )}
              .
            </li>
            <li>
              {t(
                'Tax regulators and other authorities who require reporting of processing activities in certain circumstances'
              )}
              .
            </li>
          </ul>
        </div>
        <div className="modal-subtitle">
          <p className="res-font-formlabel">
            {t('Last updated: October 2020')}
          </p>
        </div>
      </div>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        animation={false}
        scrollable={true}
        dialogClassName="modal"
      >
        {content}
      </Modal>
    </div>
  );
};

export default PrivacyPolicy;
