import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMiniBooks,
  fetchMiniBooks,
  fetchTopRatedMiniBooks,
} from "../../features/miniBooksSlice";
import { auth } from "../../features/authSlice";
import { useTranslation } from "react-i18next";
import { MiniBookComponent } from "../list_component/MiniBookComponent";
import { MiniBookHeader } from "../list_component/MiniBookHeader";
import ListLastRow from "../list_component/ListLastRow";
import constants from "../../constants/AppConstants";
const MiniBooksFeed = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(auth);
  const { minibooks } = useSelector(selectMiniBooks);

  const [numberOfBooks, setNumberOfBooks] = useState(5);

  const maxNumberOfBooks = 10;

  let locale = t("currentLocale");
  useEffect(() => {
    if (
      localStorage.getItem(constants.BOOKSFILTERSTATE) == constants.TOPRATED
    ) {
      dispatch(fetchTopRatedMiniBooks("published"));
    } else {
      dispatch(fetchMiniBooks("published"));
    }
  }, [dispatch, locale, isAuthenticated]);

  const handleClick = () => {
    if (numberOfBooks < maxNumberOfBooks) {
      setNumberOfBooks(numberOfBooks + 5);
    } else if (numberOfBooks >= maxNumberOfBooks) {
      setNumberOfBooks(5);
    }
  };
  // when rating is given, reload learnings
  const reloadBooks = () => {
    if (
      localStorage.getItem(constants.BOOKSFILTERSTATE) == constants.NEWEST
    ) {
      dispatch(fetchMiniBooks());
    } else {
      dispatch(fetchTopRatedMiniBooks());
    }
  };
  const filterTopRated = () => {
    localStorage.setItem(constants.BOOKSFILTERSTATE, constants.TOPRATED);
    dispatch(fetchTopRatedMiniBooks());
  };

  const filterNewest = () => {
    localStorage.setItem(constants.BOOKSFILTERSTATE, constants.NEWEST);
    dispatch(fetchMiniBooks());
  };

  return (
    <>
      <div className="top-rated-main">
        <div className="top-rated-wrapper">
          <MiniBookHeader
            title={
              localStorage.getItem(constants.BOOKSFILTERSTATE) ==
              constants.TOPRATED
                ? t("list>Top Rated Mini Books")
                : t("list>Most Recent Mini Books")
            }
            ratingTitle={t("list>Average Rating")}
            profileTitle={t("list>Mini Book")}
            filterTopRated={filterTopRated}
            filterNewest={filterNewest}
          />
          <MiniBookComponent
            minibooks={minibooks}
            numberOfBooks={numberOfBooks}
            reloadFunc={reloadBooks}
          />
          <ListLastRow
            handleClick={handleClick}
            numberOfItems={numberOfBooks}
            maxNumberOfItems={maxNumberOfBooks}
          />
        </div>
      </div>
    </>
  );
};
export default MiniBooksFeed;
