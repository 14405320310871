import React from "react";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const MiniBookHeader = (props) => {
  MiniBookHeader.propTypes = {
    title: PropTypes.string,
    ratingTitle: PropTypes.string,
    profileTitle: PropTypes.string,
    filterTopRated: PropTypes.func,
    filterNewest: PropTypes.func,
    showOnlyTitle: PropTypes.bool,
  };
  const { t } = useTranslation();
  const filterTopRated = () => {
    props.filterTopRated();
  };

  const filterNewest = () => {
    props.filterNewest();
  };
  return (
    <ul className="headers minibook-headers mt-3">
      <li className="headers-title headers-first">
        <p>{props.title}</p>
      </li>
      {!props.showOnlyTitle ? (
        <>
          <li className="dropdown headers-dropdown">
            <DropdownButton id="dropdown-item-button" title={t("list>Filter")}>
              <Dropdown.Item as="button" className="dropdown-button" onClick={() => filterTopRated()}>
                {t("list>Top Rated")}
              </Dropdown.Item>
              <Dropdown.Item as="button" className="filter-button" onClick={() => filterNewest()}>
                {t("list>Newest")}
              </Dropdown.Item>
            </DropdownButton>
          </li>

          <li className="headers-title headers-second">
            <p>{props.ratingTitle}</p>
          </li>
          <li className="headers-title headers-third">
            <p>{props.profileTitle}</p>
          </li>
        </>
      ) : (
        ""
      )}
    </ul>
  );
};

export default MiniBookHeader;
