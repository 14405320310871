import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { auth, setNextURL } from "../../features/authSlice";
import { setLoginOrCreateAccount } from "../../features/modalSlice";
import { removeMiniBook } from "../../features/miniBooksSlice";

export const GetFullStoryLI = (props) => {
  GetFullStoryLI.propTypes = {
    isAdmin: PropTypes.bool,
    minibook: PropTypes.object,
  };

  const [isRemoved, setIsRemoved] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector(auth);

  const openModal = (
    // when user is not authenticated
    <button
      className="button button-transparent btn-get-story"
      onClick={() => {
        dispatch(setNextURL(`/minibook/${props.minibook.slug}`))
        dispatch(setLoginOrCreateAccount(true));
      }}
    >
      <span>{t("list>Get Full Story")}</span>
    </button>
  );

  const handleRemove = (slug) =>{
    dispatch(removeMiniBook(slug))
    setIsRemoved(true)
  }

  const deleteBookBtn = (slug) =>
  {
    return (
      <button
        className="button button-transparent"
        onClick={() => handleRemove(slug)}
        disabled={isRemoved}
      >
        <span>{isRemoved?t("list>Removed"):t("list>Remove")}</span>
      </button>
    );
  }

  return (
    <li className="list-item fourth">
      {isAuthenticated?
      <button
        className="button button-transparent"
      >
        <Link
          className="link-black btn-get-story"
          to={`/minibook/${props.minibook.slug}`}
        >
          <span>{t("list>Get Full Story")}</span>
        </Link>
      </button>
      :
      openModal
      }
      {props.isAdmin?deleteBookBtn(props.minibook.slug):""}
    </li>
  );
};

export default GetFullStoryLI;
