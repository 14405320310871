import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectLifeLearnings,
  fetchTopRatedLifeLearnings,
  fetchLifeLearnings,
} from "../../features/lifeLearningsSlice";
import { Modal } from "react-bootstrap";
import { auth } from "../../features/authSlice";

import ListHeader from "../list_component/ListHeader";
import LearningComponent from "../list_component/LearningComponent";
import ListLastRow from "../list_component/ListLastRow";
import SavedLifeLearningContent from "../modals/modalContents/SavedLifeLearningContent";
import { setLoginOrCreateAccount } from "../../features/modalSlice";
import constants from "../../constants/AppConstants";

const LifeLearningsFeed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showSaved, setShowSaved] = useState(false);

  const { lifeLearnings } = useSelector(selectLifeLearnings);
  const { isAuthenticated } = useSelector(auth);

  const [numberOfItems, setNumberOfItems] = useState(5);

  let locale = t("currentLocale");

  useEffect(() => {
    if (
      localStorage.getItem(constants.LEARNINGSFILTERSTATE) == constants.TOPRATED
    ) {
      dispatch(fetchTopRatedLifeLearnings());
    } else {
      dispatch(fetchLifeLearnings());
    }
  }, [dispatch, isAuthenticated, maxNumberOfItems, locale]);

  const maxNumberOfItems = 10;

  const handleClick = () => {
    if (numberOfItems < maxNumberOfItems) {
      setNumberOfItems(numberOfItems + 5);
    } else if (numberOfItems >= maxNumberOfItems) {
      setNumberOfItems(5);
    }
  };

  const filterTopRated = () => {
    localStorage.setItem(constants.LEARNINGSFILTERSTATE, constants.TOPRATED);
    dispatch(fetchTopRatedLifeLearnings());
  };
  const filterNewest = () => {
    localStorage.setItem(constants.LEARNINGSFILTERSTATE, constants.NEWEST);
    dispatch(fetchLifeLearnings());
  };

  // when rating is given, reload learnings
  const reloadLearnings = () => {
    if (
      localStorage.getItem(constants.LEARNINGSFILTERSTATE) ==
      constants.NEWEST
    ) {
      filterNewest();
    } else {
      filterTopRated();
    }
  };
  const closeModalSavedLifeLearnings = () => {
    reloadLearnings();
    setShowSaved(false);
  };
  const openModalLoginOrCreateAccount = () => {
    dispatch(setLoginOrCreateAccount(true));
  };
  const openModalSavedLifeLearnings = () => {
    setShowSaved(true);
  };

  const openModal = () => {
    {
      isAuthenticated
        ? openModalSavedLifeLearnings()
        : openModalLoginOrCreateAccount();
    }
  };

  return (
    <div className="top-rated-main">
      <div className="top-rated-wrapper">
        <ListHeader
          title={
            localStorage.getItem(constants.LEARNINGSFILTERSTATE) ==
            constants.TOPRATED
              ? t("list>Top Rated Life Learnings")
              : t("list>Most Recent Life Learnings")
          }
          ratingTitle={t("list>Rate Value of Learning")}
          profileTitle={t("list>Profile")}
          filterTopRated={filterTopRated}
          filterNewest={filterNewest}
        />
        {lifeLearnings.length == 0 && isAuthenticated ? (
          <ul className="flexbox learning-feed-flexbox no-results-learning-feed-flexbox">
            <li className="list-item first">
              <div className="list-item-text-wrapper">
                <div className="no-results res-font-bold-small">
                  {t(
                    "list>No Life Learnings can be found with the categories you have selected."
                  )}
                </div>
              </div>
            </li>
          </ul>
        ) : (
          lifeLearnings
            .slice(0, numberOfItems)
            .map((lifeLearning) => (
              <LearningComponent
                key={lifeLearning.id}
                body={lifeLearning.content}
                openModal={openModal}
                isSaved={lifeLearning.is_saved}
                avgRating={lifeLearning.avg_rating}
                ratingsCount={lifeLearning.ratings_count}
                category={lifeLearning.category_info.name}
                reloadFunc={reloadLearnings}
                id={lifeLearning.id}
              />
            ))
        )}
        <Modal
          show={showSaved}
          onHide={() => closeModalSavedLifeLearnings()}
          animation={false}
          dialogClassName="modal-100w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <SavedLifeLearningContent closeModal={closeModalSavedLifeLearnings} />
        </Modal>

        <ListLastRow
          handleClick={handleClick}
          numberOfItems={numberOfItems}
          maxNumberOfItems={maxNumberOfItems}
        />
      </div>
    </div>
  );
};

export default LifeLearningsFeed;
