import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import LoginForm from "../../login/LoginForm";

const LogIn = (props) => {
  LogIn.propTypes = {
    closeModal: PropTypes.func,
    openModalSign: PropTypes.func,
  };
  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalbody">
        <LoginForm closeModal={props.closeModal} />
      </Modal.Body>
    </>
  );
};

export default LogIn;
