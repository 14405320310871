import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SavedLifeLearningContent = (props) => {
  SavedLifeLearningContent.propTypes = {
    closeModal: PropTypes.func,
  };

  const { t } = useTranslation();

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title id="saved-lifelearning"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbody">
        <div className="modal-width">
          <div className="savedlearning-container">
            <div className="login-logo">
              <div className="logotext">
                <span className="life">Life</span>
                <span className="learning">Learnings</span>
              </div>
            </div>
            <div>
              <div className="res-font-extra-header  savedlearning-label">
                <label>{t("modal>Saved!")}</label>
              </div>
              <div className="res-font-extra-bold-medium savedlearning-text">
                {t(
                  "modal>You can now view this Life Learnings by going to Saved Life Learnings section of your profile."
                )}
              </div>
              <div className="res-font-regular-medium savedlearning-footer">
                {t(
                  "modal>If you shared this life learning, you can also view it on My Life Learnings page."
                )}
              </div>
              <div className="savedlearning-button-container">
                <div>
                  <button
                    className="button-black button-w130 "
                    onClick={() => props.closeModal()}
                  >
                    {t("modal>Close")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </div>
  );
};

export default SavedLifeLearningContent;
