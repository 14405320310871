import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";

import NavbarModals from "../modals/NavbarModals";
import LanguageSelect from "./LanguageSelect";
import { auth, renewWithCookie } from "../../features/authSlice";
import Cookie from "universal-cookie";
import {
  getMyBookRatings,
  getMyLearningRatings,
  setBookRatings,
  setLearningRatings,
} from "../../features/ratingSlice";

const cookies = new Cookie();

const PrimaryNavbar = () => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(auth);

  // Universal actions on page load
  useEffect(() => {
    // Prevent requests from unauthenticated users
    if (cookies.get("logged_in") == "true") dispatch(renewWithCookie());
  }, [dispatch]);

  // Retrieve user's own ratings
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMyLearningRatings());
      dispatch(getMyBookRatings());
    } else {
      dispatch(setLearningRatings({}));
      dispatch(setBookRatings({}));
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Navbar>
      <div className="navbar-link">
        <Link className="logotext" to="/">
          <span className="life">Life</span>
          <span className="learning">Learnings</span>
        </Link>
      </div>
      <NavbarModals />
      <LanguageSelect />
    </Navbar>
  );
};

export default PrimaryNavbar;
