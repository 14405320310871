import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import picture1 from "../../../assets/images/paula.jpg";
import picture1webp from "../../../assets/images/paula.webp";

const AboutUs = () => {
  const { t } = useTranslation("aboutUs");

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="aboutus"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbody">
        <div className="aboutus-container">
          <div className="login-logo">
            <div className="logotext">
              <span className="life">Life</span>
              <span className="learning">Learnings</span>
            </div>
          </div>
          <div className="aboutustext-container res-font-extra-header">
            {t("About Us")}
          </div>
          <div className="aboutus-header">
            <h3 className="res-font-header-small">
              {t("Bringing guidance, healing and growth to the world.")}
            </h3>
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t(
              "Our foundation lies in the hope for a kinder, more connected world where people feel \
empowered through their experiences. Not everyone is proud of their past – our mission is to \
encourage individuals to embrace the good and the bad parts of their lives and share the meaning \
within them."
            )}
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t("The belief that")} <b>{t("every")}</b>{" "}
            {t(
              "story holds a valuable piece to the puzzle of human understanding has \
driven us to bring together people of all cultures, ages and identities. We hope to provide our \
members with an easy way to access worldly and generational knowledge, while promoting one \
beautiful sentiment – that none of us are alone."
            )}
          </div>
          <div className="aboutus-header res-font-header-small">
            {t("Where We Came From")}
          </div>
          <div className="passion-header res-font-medium-regular">
            {t("My passion is people.")}
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t(
              "Life Learnings began with a 3am cry for mommy from my 2-year old daughter, Sky. After \
getting her back to sleep, I fell into a half-conscious wake state where I saw purple specks \
meshing together to form a beautiful, rich color against the blackness. This is something people \
often experience in meditation, signifying that you are in connection with the spiritual realm. \
During this experience, I was given a clear vision of this forum – how it will work, what it will \
do and how valuable it will be to others."
            )}
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t(
              "My life mission is to reveal the beauty of the human experience and connect people from all over \
the world. I want people to learn from each other and grow together. The concept of Life \
Learnings did not come from me. Although the idea has been somewhere in my heart for a few \
years, I feel a higher power in the universe channeled it through me during this time of worldly \
change and transition. 2021 was the right time to bring it to life."
            )}
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t(
              "This isn’t the first time I’ve been called on from the universe. Following my intuition in the past \
led me to creating an eLearning company, moving from Europe to the USA, getting a master’s in \
human resources, becoming a certified life coach and bringing a beautiful female soul into this \
world."
            )}
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t(
              "Because of my experiences, I wanted to create something that will give back and support all \
kinds of people. That’s why the forum is"
            )}{" "}
            <b>{t("free")}</b>{" "}
            {t(
              "and 10% of our yearly profit goes to our community’s chosen charity."
            )}
          </div>
          <div className=" aboutus-header res-font-header-small">
            {t("Special Thanks")}
          </div>
          <div className="aboutus-text res-font-medium-regular">
            {t(
              "Life Learnings was built by a network of amazing, intelligent and loving people who came \
together to make this vision a reality. I want to thank the technical and design experts in Finland, \
Turku Työllisyyspalvelukeskus who designed this forum and all of the worldwide friends and \
professionals that contributed to its success. It’s been truly inspiring to experience so many \
wonderful human beings come together and build something for the greater good. I am \
extremely grateful for all of our members, the people who made this forum come alive, and for \
the universe itself for giving me an arena to express my soul purpose and passion of helping \
others."
            )}
          </div>
          <div className="aboutus-header res-font-header-small aboutus-text res-font-medium-regular">
            {t("Paula Reed, CEO, Life Learnings LLC")}
          </div>
          <div className="aboutus-picture">
            <picture>
              <source srcSet={picture1webp} type="image/webp" />
              <source srcSet={picture1} type="image/jpeg" />
              <img src={picture1} type="image/jpeg" alt="person" />
            </picture>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AboutUs;
