import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../features/authSlice";
import PropTypes from "prop-types";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import ResetPasswordModal from "../../components/resetpassword/ResetForm";
import { setLogin, setSignIn } from "../../features/modalSlice";
import { auth } from "../../features/authSlice";
import { setPrivacyPolicy, setTermsOfUse } from "../../features/modalSlice";

const LoginForm = (props) => {
  LoginForm.propTypes = {
    closeModal: PropTypes.func,
  };
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(null);
  const [capsLock, setCapsLock] = useState(false);
  const [status, setStatus] = useState(null);

  const { nextURL } = useSelector(auth);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModalReset = () => {
    setShow(false);
  };
  const openModalReset = () => {
    setShow(true);
    setContent(<ResetPasswordModal closeModal={closeModalReset} />);
  };

  /**
   * Detect caps lock being on when typing.
   * @param keyEvent On key down event.
   */
  const onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="login-logo">
          <div className="logotext">
            <span className="life">Life</span>
            <span className="learning">Learnings</span>
          </div>
        </div>
        <div className="login-text res-font-regular-medium-3">
          <p className="mb-0">{t("modal>By continuing, you agree to our")}</p>
          <span
            className="clickable-span-text"
            onClick={() => dispatch(setTermsOfUse(true))}
          >
            {t("modal>Terms of Use")}
          </span>
          <span> {t("modal>and")} </span>
          <span
            className="clickable-span-text"
            onClick={() => dispatch(setPrivacyPolicy(true))}
          >
            {t("modal>Privacy Policy.")}
          </span>
        </div>
        <Formik
          initialValues={{
            grant_type: "password",
            username: "",
            password: "",
            scope: "openid email profile",
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required(t("modal>Email is required")),
            password: Yup.string()
              .min(8, t("modal>Your password must be at least 8 characters"))
              .matches(
                /^(?=.*[A-Za-z])/,
                t("modal>Your password can't be entirely numeric")
              )
              .required(t("modal>Password is required")),
          })}
          onSubmit={(fields) => {
            if (typeof props.closeModal !== "undefined") props.closeModal();
            dispatch(loginUser(fields, navigate, nextURL, setStatus));
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="login-form mt-4">
                <div className="form-group">
                  <Field id="grant_type" name="grant_type" type="hidden" />
                  <label className="login-label" htmlFor="username">
                    {t("modal>Email")}
                  </label>
                  <Field
                    id="username"
                    name="username"
                    type="text"
                    className={
                      "form-control" +
                      (errors.username && touched.username ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label className="login-label" htmlFor="password">
                    {t("modal>Password")}
                  </label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="on"
                    onKeyDown={onKeyDown}
                    className={
                      "form-control" +
                      (errors.password && touched.password ? " is-invalid" : "")
                    }
                  />
                  {capsLock ? (
                    <div className="caps-lock-message">
                      {t("modal>Caps Lock is enabled")}
                    </div>
                  ) : (
                    ""
                  )}
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <Field id="scope" name="scope" type="hidden" />
                <div className="status-message failure">
                  {status}
                </div>
                <div className="login-button-container">
                  <button type="submit" className="login-button button-black">
                    {t("modal>Login")}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="reset-password">
          <button
            className="btn-reset-password button-transparent"
            onClick={() => openModalReset()}
          >
            {t("modal>Reset Password")}
          </button>
        </div>
        <div className="register-text">
          <p>{t("modal>New to LifeLearnings.com?")} </p>
          <button
            className="btn-reset-password button-transparent"
            onClick={() => {
              dispatch(setSignIn(true));
              dispatch(setLogin(false));
            }}
          >
            {t("modal>JOIN FOR FREE")}
          </button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modalbody">{content}</Modal.Body>
      </Modal>
    </>
  );
};

export default LoginForm;
