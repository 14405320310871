import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OpenPdfLink from "../../components/utils/OpenPdfLink";
import { useTranslation } from "react-i18next";
import styles from "../../styles/components/modals/_needhelp.module.scss";
import PdfEn from "../../assets/stripe-account-guide.pdf";
import PdfFi from "../../assets/stripe-ohje.pdf";

const NeedHelpModal = () => {
  const [pdfFile, setPdfFile] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "fi") {
      setPdfFile(PdfFi);
    } else {
      setPdfFile(PdfEn);
    }
  }, [i18n.language]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbody">
        <div className="modal-width">
          <div className="need-help-container">
            <div className="logo-container">
              <div className="logotext">
                <span className="life">Life</span>
                <span className="learning">Learnings</span>
              </div>
            </div>
            <div className={styles.modalTextContainer}>
              <div className={styles.modalHeaderText}>
                <h1 className="res-font-extra-header">
                  {t("needHelp>Need Help?")}
                </h1>
              </div>
              <div className={styles.modalSubtitle}>
                <p className="res-font-bold-medium">
                  {t(
                    "needHelp>Browse through our FAQs to find the answers you’re looking for. \
If you need more help, please feel free to contact our support team."
                  )}
                </p>
              </div>
              <div className={styles.modalSubtitle}>
                <p className="res-font-extra-bold-medium">
                  {t("needHelp>Short Life Learnings")}
                </p>
              </div>
              <div className="need-help-questions">
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How do I access the Life Learnings I have shared?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Go to Profile and select My Life Learnings. You’ll be able to view, \
edit or delete your Life Learnings here."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How do I access the Life Learnings that I have saved form others?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Go to Profile and select Saved Life Learnings. \
You can view and remove Life Learnings here."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How can I delete the Life Learnings that I shared?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Go to Profile and select My Life Learnings. \
Find the Life Learning you would like to remove and click Delete."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How can I edit a Life Learning that I submitted?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>You cannot edit a Life Learning. You must delete it and create a new one. \
Go to Profile and select My Life Learnings. Find the Life Learning you would like to remove and click Delete. \
Then, create a new one."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Are the Life Learnings on the home page significant in some way?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Yes! Top-rated Life Learnings and mini books that match your interests are displayed on the home page. \
The Life Learnings platform automatically shows you the top-rated items from the life categories that you selected when you created \
a profile. You can change your interests anytime to adjust the feed on the front page. \
You can do this by going to Profile and reselecting your interests."
                    )}
                  </p>
                </div>
                <div className={styles.modalSubtitle}>
                  <p className="res-font-extra-bold-medium">
                    {t("needHelp>Mini Books")}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t("needHelp>How do I create a mini book?")}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Before you create a mini book, you must create a short Life Learning first. The two are always linked. \
This is because your mini book is meant to explain the background and provide context to a Life Learning."
                    )}
                    <br /> <br />
                    {t(
                      "needHelp>If you already posted a short Life Learning, go to Profile, and select My Life Learnings. \
Then, click Create Mini Book. If you did not already share a short Life Learning, go to the Share Life Learnings page, \
and create a Life Learning. The system will prompt you to create a mini book after you click Share."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How do I access the mini books I have shared?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Go to Profile and select My Life Learnings. You’ll be able to view, edit, share, or delete your mini books here."
                    )}
                    <br /> <br />
                    {t(
                      "needHelp>Note: For the convenience of our members, after you delete a mini book, it will stay visible \
to those who have saved it or made contributions. After 30 days, your mini book will be deleted from the Life Learnings servers \
and all members will lose access to it."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How can I receive contributions towards the mini books that I created?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>To receive contributions, you must have an active Stripe account. You can create \
(or connect an existing one) by going to Profile and selecting My Account. Once you link your Life Learnings account with Stripe, \
you will automatically become eligible for contributions. Also, you will be prompted to create a Stripe account \
after you have published a mini book."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t("needHelp>How much money do I get from contributions?")}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>You will receive 40% of gross contributions, while Life Learnings will use the remaining 60% \
to make our platform better for you and our members."
                    )}
                    <br /> <br />
                    {t("needHelp>Processing and Fees:")}
                    <br /> <br />
                    {t(
                      "needHelp>The gross contribution amount (the entire amount that a member donates) will be transferred to \
your Stripe account, and 60% of the contribution will be credited to Life Learnings. Stripe also deducts a transfer fee \
from the total amount a member contributed. Stripe fees depend on the country and may vary \
(e.g. in US 2.9% + 30¢ per successful card charges)."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How can I get support when creating or connecting a Stripe account?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <div className="res-font-regular-medium-4">
                    <OpenPdfLink
                      spanText1={t("myAccount>Review")}
                      text={t("myAccount>detailed instructions")}
                      document={pdfFile}
                      spanText2={t(
                        "myAccount>on how to create or connect a Stripe account."
                      )}
                    />
                  </div>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>If you experience any issues or have any questions about setting up your account, \
please contact Stripe’s 24/7 support."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How can I view the contributions given to my mini books?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>After you set up your Stripe account, you can easily monitor contributions on your Stripe dashboard \
under All Transactions. Or, you can go to your Life Learnings profile and select My Account."
                    )}
                  </p>
                </div>
                <div className={styles.modalSubtitle}>
                  <p className="res-font-extra-bold-medium">
                    {t("needHelp>Profile")}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How do I edit my personal information (writer name, email, bio, life categories)?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>You can edit your personal information by going to Profile."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t("needHelp>How do I delete my profile?")}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>You can delete your profile by going to Profile and selecting Delete Profile."
                    )}
                    <br /> <br />
                    {t(
                      "needHelp>Note: For the convenience of our members, your mini books will stay visible to those who have saved it \
or made contributions. After 30 days, your mini book will be deleted from the Life Learnings servers \
and all members will lose access to it."
                    )}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t("needHelp>How do I edit my Bio?")}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t("needHelp>You can edit your bio by going to Profile.")}
                  </p>
                </div>
                <div className={styles.modalSubtitle}>
                  <p className="res-font-extra-bold-medium">
                    {t("needHelp>Charities")}
                  </p>
                </div>
                <div className="need-help-question">
                  <p className="res-font-bold-medium">
                    {t("needHelp>Question")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>How can I learn more about the charity options that I can select from when I donate?"
                    )}
                  </p>
                </div>
                <div className={styles.needHelpAnswer}>
                  <p className="res-font-bold-medium">
                    {t("needHelp>Answer")}:
                  </p>
                  <p className="res-font-regular-medium">
                    {t(
                      "needHelp>Here is the list of charities and link to their web site:"
                    )}
                  </p>
                  <ul className={styles.charitiesList}>
                    <li>
                      Save the Children (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.savethechildren.org"
                      >
                        www.savethechildren.org
                      </a>
                      )
                    </li>
                    <li>
                      GiveDirectly (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.givedirectly.org"
                      >
                        www.givedirectly.org
                      </a>
                      )
                    </li>
                    <li>
                      Partners in Health (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.pih.org"
                      >
                        www.pih.org
                      </a>
                      )
                    </li>
                    <li>
                      World Resources Institute (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.wri.org"
                      >
                        www.wri.org
                      </a>
                      )
                    </li>
                    <li>
                      All Hands and Hearts - Smart Response (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.allhandsandhearts.org"
                      >
                        www.allhandsandhearts.org
                      </a>
                      )
                    </li>
                    <li>
                      Unbound (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.unbound.org"
                      >
                        www.unbound.org
                      </a>
                      )
                    </li>
                    <li>
                      Action Against Hunger (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.actionagainsthunger.org"
                      >
                        www.actionagainsthunger.org
                      </a>
                      )
                    </li>
                    <li>
                      National Alliance to End Homelessness (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.endhomelessness.org"
                      >
                        www.endhomelessness.org
                      </a>
                      )
                    </li>
                    <li>
                      Animal Welfare Institute (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.awionline.org"
                      >
                        www.awionline.org
                      </a>
                      )
                    </li>
                    <li>
                      Prevent Child Abuse America (National Office) (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.preventchildabuse.org"
                      >
                        www.preventchildabuse.org
                      </a>
                      )
                    </li>
                    <li>
                      National Council on Aging (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.ncoa.org"
                      >
                        www.ncoa.org
                      </a>
                      )
                    </li>
                    <li>
                      Big Brothers/Big Sisters of America (National Office) (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.bbbs.org"
                      >
                        www.bbbs.org
                      </a>
                      )
                    </li>
                    <li>
                      Fisher House Foundation (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.fisherhouse.org"
                      >
                        www.fisherhouse.org
                      </a>
                      )
                    </li>
                    <li>
                      Equal Justice Initiative (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.eji.org"
                      >
                        www.eji.org
                      </a>
                      )
                    </li>
                    <li>
                      Scholarship America (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.scholarshipamerica.org"
                      >
                        www.scholarshipamerica.org
                      </a>
                      )
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default NeedHelpModal;
