import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const VideoPlayer = () => {
  const [youtubeId, setYoutubeId] = useState("0iD2PTAsGOE");
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "fi") {
      setYoutubeId("A-gBPt6C9J0");
    } else {
      setYoutubeId("0iD2PTAsGOE");
    }
  }, [i18n.language]);

  return (
    <div className="video-container">
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0,
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={`https://www.youtube.com/embed/${youtubeId}`}
          frameBorder="0"
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
