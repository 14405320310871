import React from "react";
import { useTranslation } from "react-i18next"
import CarouselCard from "../../components/carousel/CarouselCard";

import image1 from "../../assets/images/carousel1.jpg";
import image2 from "../../assets/images/carousel2.png";
import image3 from "../../assets/images/carousel3.png";
import image4 from "../../assets/images/carousel4.png";

import image1webp from "../../assets/images/carousel1.webp";
import image2webp from "../../assets/images/carousel2.webp";
import image3webp from "../../assets/images/carousel3.webp";
import image4webp from "../../assets/images/carousel4.webp";

const Carousel = () => {

  const { t } = useTranslation();

  return (
    <div>
      <CarouselCard
        img1={image1}
        img2={image2}
        img3={image3}
        img4={image4}
        img1webp={image1webp}
        img2webp={image2webp}
        img3webp={image3webp}
        img4webp={image4webp}
        text1={t("home>Reimagine our world by sharing yours.")}
        text2={t("home>Share a short learning or mini book – it’s easy.")}
        text3={t("home>A global network of life learnings and mini books.")}
        text4={t("home>Publish as many mini books as you want and readers can donate.")}
        title1={t("home>We'll help.")}
        title2={t("home>Start today.")}
        title3={t("home>Learn from others.")}
        title4={t("home>Make money for FREE.")}
      />
    </div>
  );
};

export default Carousel;
