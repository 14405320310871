import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaStar } from "react-icons/fa";
import {
  postStarRating,
  giveStarRatingToBook,
  getMyBookRatings,
  getMyLearningRatings,
} from "../../features/ratingSlice";
import constants from "../../constants/AppConstants";
//import ReactStars from "react-rating-stars-component";

const StarRateModal = (props) => {
  const [rating, setRating] = useState(null);
  const [visualRating, setVisualRating] = useState(null);
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  StarRateModal.propTypes = {
    idOrSlug: PropTypes.string,
    isBook: PropTypes.bool,
    closeStarRateModal: PropTypes.func,
    ratingsCount: PropTypes.number,
    reloadFunc: PropTypes.func,
  };
  const handleClick = () => {
    setRating(rating);
    if (rating == null) return;

    // if not book or book prop not set rate lifelearning
    if (!props.isBook) {
      dispatch(postStarRating(props.idOrSlug, rating));
    } else {
      dispatch(giveStarRatingToBook(props.idOrSlug, rating));
    }

    // if the ratingsCount is less than the amount of ratings that are cached,
    // refetch for immediate update.
    if (props.ratingsCount < constants.NUM_RATINGS_WHEN_TO_USE_CACHING) {
      // load with a small delay so that the back end has time to update
      setTimeout(() => {
        props.reloadFunc();
        props.isBook?dispatch(getMyBookRatings()):dispatch(getMyLearningRatings());
      }, 1000);
    }

    props.closeStarRateModal();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="star-rate-modal"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbody">
        <div className="starrate-container">
          <div className="starrate-text-container res-font-bold-medium-big">
            {t("starRate>Please, rate the life learning.")}
          </div>
          {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (
              <label key={i}>
                <input
                  className="starrating"
                  type="radio"
                  name="score"
                  value={ratingValue}
                  //Commented out, Paula's wish. submission by clicking on the star.
                  //onClick={() => handleClick(ratingValue)}
                />
                <FaStar
                  className="star"
                  size={100}
                  color={ratingValue <= visualRating ? "#F0CF4E" : "#d5d6dd"}
                  onMouseEnter={selected?()=>{}:() => setVisualRating(ratingValue)}
                  onClick={() => {
                    setVisualRating(ratingValue);
                    setRating(ratingValue);
                    setSelected(true);
                  }}
                  onMouseLeave={selected?()=>{}:() => setVisualRating(null)}
                />
              </label>
            );
          })}
          <div className="starrate-button-container">
            <div className="starrate-button">
              <button
                className="button-black button-w130"
                onClick={() => props.closeStarRateModal()}
              >
                {t("Cancel")}
              </button>
            </div>
            <div className="starrate-button">
              <button
                className="button-black button-w130"
                onClick={handleClick}
              >
                {t("Submit")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};
export default StarRateModal;
