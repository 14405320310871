import React from "react";
import FooterModals from "../modals/FooterModals";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="footer">
      <FooterModals />
      <div className="footer-logo">
        <div className="logotext">
          <span className="life">Life</span>
          <span className="learning">Learnings</span>
        </div>
      </div>
      <div className="copyright-text">
        <span>Copyright &copy; {year} Life Learnings LLC. </span>
        <span>{t("footer>All Rights Reserved")}.</span>
      </div>
    </div>
  );
};

export default Footer;
