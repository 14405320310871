import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { auth } from "../../features/authSlice";
import StarRateModal from "../modals/StarRateModal";
import StarRatingResult from "./StarRatingResult";
import {
  setLoginOrCreateAccount,
  setLoginPromptCustomHeader,
  setLoginPromptCustomText,
} from "../../features/modalSlice";
import { selectStarRating } from "../../features/ratingSlice";

const StarRating = (props) => {
  StarRating.propTypes = {
    idOrSlug: PropTypes.string,
    isBook: PropTypes.bool,
    ratingsCount: PropTypes.number,
    avgRating: PropTypes.number,
    reloadFunc: PropTypes.func,
  };

  const { bookRatings, learningRatings } = useSelector(selectStarRating);

  const getUserRating = () => {
    let ratingDic = props.isBook?bookRatings:learningRatings;
    if (props.idOrSlug in ratingDic)
      return ratingDic[props.idOrSlug];
    else
      return null;
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showStarRateModal, setShowStarRateModal] = useState(false);

  const { isAuthenticated } = useSelector(auth);
  const closeStarRateModal = () => {
    setShowStarRateModal(false);
  };

  const openStarRateModal = () => {
    setShowStarRateModal(true);
  };
  const handleClick = () => {
    if (isAuthenticated) {
      openStarRateModal();
    } else {
      dispatch(setLoginPromptCustomHeader(t("modal>Login Required")));
      dispatch(
        setLoginPromptCustomText(
          t(
            "modal>Please login or create a FREE profile to be able to rate life learnings."
          )
        )
      );
      dispatch(setLoginOrCreateAccount(true));
    }
  };
  return (
    <div className="rating-container">
      <StarRatingResult
        avgRating={props.avgRating}
        openRateModal={true}
        openRateModalFunc={handleClick}
        userRating={getUserRating()}
      />
      <Modal
        show={showStarRateModal}
        onHide={() => setShowStarRateModal(false)}
        dialogClassName="modal-100w"
        centered
      >
        <StarRateModal
          idOrSlug={props.idOrSlug}
          isBook={props.isBook}
          ratingsCount={props.ratingsCount}
          closeStarRateModal={closeStarRateModal}
          reloadFunc={props.reloadFunc}
        />
      </Modal>
    </div>
  );
};

export default StarRating;
