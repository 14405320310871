import React from "react";
import PropTypes from "prop-types";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const StarRatingResult = (props) => {
  StarRatingResult.propTypes = {
    avgRating: PropTypes.number,
    openRateModal: PropTypes.bool,
    openRateModalFunc: PropTypes.func,
    userRating: PropTypes.number,
  };
  
  function userOutline(ratingValue) {
    if (ratingValue==props.userRating)
      return "outlined"
    else
      return ""
  }

  const create_stars = (num) =>{
    let num_stars = Math.round(num * 2)/ 2
    let decimal = num_stars % 1
    let half_stars = num_stars % 1 >= 0.5 ? 1 : 0
    num_stars -= decimal
    let key = 0
    const s = []

    /**Creates full stars */
    for (let index = 0; index < num_stars; index++) {
      s.push(<FaStar
        key={++key}
        className={`star ${userOutline()} yellow`}
        size={90} />)
    }
    /**Creates half star if any */
    s.push(half_stars === 1 ? <FaStarHalfAlt key={++key} className={`star ${userOutline()} yellow`} size={90} /> : "")

    /**Creates remaining empty stars */
    for (let index = 0; index < 5 - (num_stars + half_stars); index++) {
      s.push(<FaStar
        key={++key}
        className={`star ${userOutline()} gray`}
        size={90} />)
    }
    return s
  }

  const handleClick = () => {
    if (props.openRateModal == true) {
      props.openRateModalFunc();
    }
  };

  return (
    <label>
      <input 
       className="starrating"
       type="radio"
       name="score"
       onClick={() => handleClick()}
      />
      {create_stars(props.avgRating)}
    </label>
  );
};

export default StarRatingResult;
