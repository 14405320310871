import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import SigninForm from "../../signin/SigninForm";

const SignIn = (props) => {
  SignIn.propTypes = {
    closeModal: PropTypes.func,
  };
  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalbody" >
        <SigninForm closeModal={props.closeModal} />
      </Modal.Body>
    </>
  );
};

export default SignIn;
