import React from 'react'
import { useTranslation } from "react-i18next"
import VideoPlayer from "../utils/VideoPlayer";

const OurMission = () => {

    const { t } = useTranslation();

    return (
        <div className="our-mission">
            <h5>{t("home>Our Mission")}</h5>
            <VideoPlayer />
            {/* <video controls>
                <source src="#" type="video/mp4" />
                <source src="#" type="video/ogg" />
                    {t("home>Your browser does not support HTML5 video.")}
            </video> */}
        </div>
    )
}

export default OurMission
