import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

const CheckBox = (props) => {
  CheckBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
  };

  return (
    <span className="checkbox__input">
      <Field
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        onClick={props.onClick}
        className={props.className}
      />
      <span className="checkbox__control">
        <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            d="M1.73 12.91l6.37 6.37L22.79 4.59"
          />
        </svg>
      </span>
    </span>
  );
};

export default CheckBox;
