import React from "react";
import BannerHomepage from "./BannerHomepage";
import picture2webp from "../../assets/images/lil-homepage-img-2.webp";
import picture3webp from "../../assets/images/lil-homepage-img-3.webp";
import picture4webp from "../../assets/images/lil-homepage-img-4.webp";

import picture2 from "../../assets/images/lil-homepage-img-2.png";
import picture3 from "../../assets/images/lil-homepage-img-3.png";
import picture4 from "../../assets/images/lil-homepage-img-4.png";

const Pictures = () => {
  
  return (
    <div className="homepage-images">
      <ul className="images-flexbox">
        <li className="images-flex-item first">
          <BannerHomepage />
        </li>
        <li className="images-flex-item second">
          <picture>
            <source srcSet={picture2webp} type="image/webp" />
            <source srcSet={picture2} type="image/png" />
            <img src={picture2} type="image/png" alt="Man" />
          </picture>
        </li>
        <li className="images-flex-item item-mobile-hide">
          <picture>
            <source srcSet={picture3webp} type="image/webp" />
            <source srcSet={picture3} type="image/png" />
            <img src={picture3} type="image/png" alt="Woman" />
          </picture>
        </li>
        <li className="images-flex-item item-mobile-hide fourth">
          <picture>
            <source srcSet={picture4webp} type="image/webp" />
            <source srcSet={picture4} type="image/png" />
            <img src={picture4} type="image/png" alt="Child" />
          </picture>
        </li>
      </ul>
    </div>
  );
};

export default Pictures;
