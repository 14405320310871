import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmail, selectResetPassword } from "../../features/resetPasswordSlice";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const ResetPasswordComponent = (props) => {
  ResetPasswordComponent.propTypes = {
    closeModal: PropTypes.func,
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {msg, hasErrors} = useSelector(selectResetPassword)

  const [mailSent, setMailSent] = useState(false);

  return (
    <>
      <div className="resetform-container">
        <div className="resetform-content">
          <div className="logotext">
            <span className="life">Life</span>
            <span className="learning">Learnings</span>
          </div>
          <div className="reset-text">
            <span>{t("reset-pw>Change forgotten password")}</span>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required(t("reset-pw>Email is required")),
            })}
            onSubmit={(fields) => {
              if (!mailSent) {
                setMailSent(true);
                dispatch(fetchEmail(fields.email));
              } else {
                props.closeModal();
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="reset-form">
                  <div className="form-group">
                    <label className="email-label" htmlFor="email">
                      {t(
                        "reset-pw>Please enter your email to reset your password"
                      )}
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder={t("reset-pw>Email")}
                      className={
                        "email formfield" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="reset-button-container">
                    <button type="submit" className="reset-button">
                      {mailSent
                        ? t("reset-pw>Close")
                        : t("reset-pw>Send email")}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className={"status-message "+(hasErrors?"failure":"success")}>
            {msg}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordComponent;
