import React from "react";
import { Carousel } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CarouselCard = (props) => {
  CarouselCard.propTypes = {
    img1: PropTypes.string,
    img2: PropTypes.string,
    img3: PropTypes.string,
    img4: PropTypes.string,
    img1webp: PropTypes.string,
    img2webp: PropTypes.string,
    img3webp: PropTypes.string,
    img4webp: PropTypes.string,
    text1: PropTypes.string,
    text2: PropTypes.string,
    text3: PropTypes.string,
    text4: PropTypes.string,
    title1: PropTypes.string,
    title2: PropTypes.string,
    title3: PropTypes.string,
    title4: PropTypes.string,
  };

  // Shows webp images in browsers that support it and .png in old browsers that don't.

  return (
    <Carousel>
      <Carousel.Item>
        <picture>
          <source srcSet={props.img1webp} type="image/webp" />
          <source srcSet={props.img1} type="image/jpeg" />
          <img
            src={props.img1}
            type="image/jpg"
            className="d-block w-100 img-carousel img-carousel-1"
            alt="First slide"
          />
        </picture>
        <Carousel.Caption>
          <p className="caption-text caption-text-1">{props.text1}</p>
          <Link to="/share">
            <p className="caption-title caption-title-1">{props.title1}</p>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source srcSet={props.img2webp} type="image/webp" />
          <source srcSet={props.img2} type="image/png" />
          <img
            src={props.img2}
            type="image/png"
            className="d-block w-100 img-carousel img-carousel-2"
            alt="Second slide"
          />
        </picture>

        <Carousel.Caption>
          <p className="caption-text">{props.text2}</p>
          <Link to="/share">
            <p className="caption-title">{props.title2}</p>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source srcSet={props.img3webp} type="image/webp" />
          <source srcSet={props.img3} type="image/png" />
          <img
            src={props.img3}
            type="image/png"
            className="d-block w-100 img-carousel"
            alt="Third slide"
          />
        </picture>

        <Carousel.Caption>
          <p className="caption-text caption-text-3">{props.text3}</p>
          <Link to="/search">
            <p className="caption-title">{props.title3}</p>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <picture>
          <source srcSet={props.img4webp} type="image/webp" />
          <source srcSet={props.img4} type="image/png" />
          <img
            src={props.img4}
            type="image/png"
            className="d-block w-100 img-carousel img-carousel-4"
            alt="Fourth slide"
          />
        </picture>

        <Carousel.Caption className="caption-4-wrapper">
          <p className="caption-text caption-text-4">{props.text4}</p>
          <p className="caption-title caption-title-4">{props.title4}</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselCard;
