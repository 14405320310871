import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyTable1 = () => {
    const { t } = useTranslation('privacyPolicy');

  return (
      <div className="flex-table flex-table-4cols">
        <div className="flex-table-cell">
          <strong>{t("Type of Data")}</strong>
        </div>
        <div className="flex-table-cell">{t("Examples of Data Collected")}</div>
        <div className="flex-table-cell">{t("How collected")}</div>
        <div className="flex-table-cell">
          {t("Why Collected/Legal Basis for Collection")}
        </div>
        {/* ---------- */}
        <div className="flex-table-cell">
          <strong>{t("Identity Data")}</strong>
        </div>
        <div className="flex-table-cell">{t("Name")}</div>
        <div className="flex-table-cell">
          {t("Provided by user when account is created")}.
        </div>
        <div className="flex-table-cell">
          <p>{t("Necessary to provide services")}</p>
          <p>{t("Collected with consent of user")}</p>
        </div>
        {/* ---------- */}
        <div className="flex-table-cell">
          <strong>{t("Contact Data")}</strong>
        </div>
        <div className="flex-table-cell">{t("Email address")}</div>
        <div className="flex-table-cell">
          {t("Provided by user when account is created")}
        </div>
        <div className="flex-table-cell">{t("Necessary to provide services")}</div>
        {/* ---------- */}
        <div className="flex-table-cell">
          <strong>{t("Transaction Data")}</strong>
        </div>
        <div className="flex-table-cell">
         {t(" Details of payments to and from you and other users, as well as\
          details about products and services purchased by you")}.
        </div>
        <div className="flex-table-cell">
          {t("Collected at the time of transaction")}.
        </div>
        <div className="flex-table-cell">
          {t("Our legitimate business interests allow us to collect such\
          non-intrusive data so we can provide our services to you")}.
        </div>
        {/* ---------- */}
        <div className="flex-table-cell">
          <strong>{t("Technical Data")}</strong>
        </div>
        <div className="flex-table-cell">
          <p>{t("IP Address")}</p>
          <p>{t("Login Data")}</p>
          <p>{t("Browser Type and Version")}</p>
          <p>{t("Time zone & Location")}</p>
          <p>{t("Browser plug-ins")}</p>
          <p>{t("Operating System")}</p>
          <p>{t("Technical details of your device")}</p>
          <p>{t("Click and viewing patterns")}</p>
        </div>
        <div className="flex-table-cell">
          {t("Collected automatically through use of cookies and other browser\
          tools")}.
        </div>
        <div className="flex-table-cell">
          {t("Our legitimate business interests allow us to collect this\
          non-intrusive data")}.
        </div>
      </div>
  );
};

export default PrivacyPolicyTable1;
