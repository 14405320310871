import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  selectShowModal,
  setLoginOrCreateAccount,
  setLogin,
  setSignIn,
  setLoginPromptCustomText,
  setLoginPromptCustomHeader,
} from "../../features/modalSlice";
import { setNextURL } from "../../features/authSlice";

const LoginOrCreateAccount = () => {
  const dispatch = useDispatch();

  const { showLoginOrCreateAccount, loginPromptCustomText, loginPromptCustomHeader } = useSelector(selectShowModal);

  const openModalLogin = () => {
    // Navbar component contains Login modal
    dispatch(setLogin(true));
  };
  const openModalSign = () => {
    // Navbar component contains Sign-in modal
    dispatch(setSignIn(true));
  };
  const { t } = useTranslation();

  return (
    <Modal
      show={showLoginOrCreateAccount}
      onHide={() => {
        dispatch(setNextURL(location.pathname));
        dispatch(setLoginOrCreateAccount(false));
        dispatch(setLoginPromptCustomText(null));
        dispatch(setLoginPromptCustomHeader(null));
      }}
      animation={false}
      dialogClassName="modal-100w"
      aria-labelledby="login-or-create-account-modal"
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title id="login-or-create-account"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalbody">
          <div className="modal-width">
            <div className="loginorcreateaccount-container">
              <div className="login-logo">
                <div className="logotext">
                  <span className="life">Life</span>
                  <span className="learning">Learnings</span>
                </div>
              </div>
              <div>
                
              {loginPromptCustomHeader?
                <div className="res-font-extra-header  loginorcreateaccount-label">
                  <label>{loginPromptCustomHeader}</label>
                </div>
                :
                <div className="res-font-extra-header  loginorcreateaccount-label">
                  <label>{t("modal>We’ve got a lot to share!")}</label>
                </div>}
                {loginPromptCustomText?
                <>
                <div className="res-font-bold-medium-big loginorcreateaccount-text">
                  {loginPromptCustomText}
                </div>
                </>
                :
                <>
                <div className="res-font-bold-medium-big loginorcreateaccount-text">
                  {t(
                    "modal>Please login or create a FREE profile to gain unlimited access \
to thousands of Life Learnings and mini books. Youll also be \
able to share your own stories and even receive donation’s from \
our wonderful community!"
                  )}
                </div>
                </>}
                <div className="loginorcreateaccount-button-container">
                  <div>
                    <button
                      className="button-black button-w130 loginorcreateaccount-button"
                      onClick={() => openModalLogin()}
                    >
                      {t("modal>Login")}
                    </button>
                  </div>
                  <button
                    className="button-black  button-w220 loginorcreateaccount-button"
                    onClick={() => openModalSign()}
                  >
                    {t("modal>Create a Profile")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LoginOrCreateAccount;
