import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "../../axios";
import StarRating from "../utils/StarRating";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../features/authSlice";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../CustomHooks/UseWindowSize";

import constants from "../../constants/AppConstants";
import { removeLifeLearning } from "../../features/lifeLearningsSlice";
const LearningComponent = (props) => {
  LearningComponent.propTypes = {
    isAdmin: PropTypes.bool,
    body: PropTypes.string,
    openModal: PropTypes.func,
    isSaved: PropTypes.bool,
    ratingCount: PropTypes.number,
    avgRating: PropTypes.number,
    category: PropTypes.string,
    ratingsCount: PropTypes.number,
    reloadFunc: PropTypes.func,
    id: PropTypes.string,
  };

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(auth);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();
  let isLongText = null;
  const windowSize = useWindowSize();
  useEffect(() => {
    if (
      windowSize <= constants.SCREEN_LG_MAX &&
      windowSize > constants.SCREEN_SM_MAX
    ) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
    if (windowSize <= constants.SCREEN_SM_MAX) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize, isTablet, isMobile]);

  if (
    isTablet &&
    (t("list>Save").length > constants.LONG_SAVED_TEXT_LENGTH ||
      t("list>Saved").length > constants.LONG_SAVED_TEXT_LENGTH)
  ) {
    isLongText = true;
  } else {
    isLongText = false;
  }

  const saveLearning = async (id) => {
    api(`/learnings/${id}/save/`, {
      method: "PATCH",
      data: JSON.stringify({}),
    })
      .then(() => {
        props.openModal();
      })
      .catch((err) => {
        console.log(err.message);
        //alert("An error occurred");
      });
  };

  const handleRemove = (id) =>{
    dispatch(removeLifeLearning(id))
    setIsRemoved(true)
  }

  const openModal = (
    //this button is shown when user is not authenticated
    <button
      className="button button-transparent btn-get-story"
      disabled={props.isSaved}
      onClick={() => props.openModal()}
    >
      <span>{t("list>Save")}</span>
    </button>
  );

  const saveLearningBtn = (
    <button
      className="button button-transparent"
      onClick={() => saveLearning(props.id)}
      disabled={props.isSaved}
      style={{
        fontSize:
          isLongText || isMobile
            ? constants.SMALL_SAVED_TEXT_SIZE
            : constants.NORMAL_SAVED_TEXT_SIZE,
      }}
    >
      {<span>{props.isSaved ? t("list>Saved") : t("list>Save")}</span>}
    </button>
  );

  const deleteLearningBtn = (
    <button
      className="button button-transparent"
      onClick={() => handleRemove(props.id)}
      disabled={isRemoved}
      style={{
        fontSize:
          isLongText || isMobile
            ? constants.SMALL_SAVED_TEXT_SIZE
            : constants.NORMAL_SAVED_TEXT_SIZE,
      }}
    >
      <span>{isRemoved?t("list>Removed"):t("list>Remove")}</span>
    </button>
  );

  return (
    <>
      <ul className="flexbox learning-feed-flexbox">
        <li className="list-item first">
          <div className="list-item-text-wrapper">
            <p className="list-item-text">{props.body}</p>
            <p className="list-item-text category-text-item">
              {t("list>Category: ")}
              {props.category}
            </p>
          </div>
        </li>
        <li className="list-item second">
          <div className="rating-wrapper">
            <div className="rating-title-mobile">
              <p>{t("list>Rate Value of Learning")}</p>
            </div>
            <StarRating
              idOrSlug={props.id.toString()}
              avgRating={props.avgRating}
              reloadFunc={props.reloadFunc}
              ratingsCount={props.ratingsCount}
            />

            <div className="ratings-amount">
              <span className="ratings-amount-text">
                ({props.ratingsCount})
              </span>
            </div>
          </div>
        </li>
        <li className="list-item third">
          {isAuthenticated ? saveLearningBtn : openModal}
          {props.isAdmin ? deleteLearningBtn : ""}
        </li>
      </ul>
    </>
  );
};

export default LearningComponent;
