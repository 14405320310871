import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

const IsHuman = (props) => {
  IsHuman.propTypes = {
    postFunc: PropTypes.func,
    setToken: PropTypes.func,
    className: PropTypes.string,
    errorMsgElem: PropTypes.instanceOf(Element),
  };

  const { i18n } = useTranslation();
  function checkIfHuman(value) {
    props.setToken(value);
    props.errorMsgElem.innerHTML = "";
  }
  return (
    <ReCAPTCHA
      className={props.className}
      sitekey={SITE_KEY}
      onChange={checkIfHuman}
      badge="bottomright"
      hl={i18n.language}
    />
  );
};
export default IsHuman;
